import React, { useMemo, useEffect, useState, useRef } from "react";
import Form from "../../../Form/Form";
import ModalForAddNew from "../../ModalForAddNew";
import FormSection from "../../../Form/FormSection";
import FormButton from "../../../Form/FormButton";
import SelectBox from "../../../Form/SelectBox";
import Input from "../../../Form/Input";
import validator from "validator";
// import Input2 from "../../Form/Input2";
import Swal from "sweetalert2";
import { FetchData } from "../../../../hooks/FetchData";
import ErrorList from "../../../Form/ErrorList";
import CheckBox from "../../../Form/CheckBox";

const AddRole = ({
  getUsers = () => {},
  connectedUser = {},
  modalData = {},
  isShowModal = false,
  handleCloseModal = function () {},
}) => {
  const formRef = useRef(null);
  //state des inputs
  const [user_role_name, set_user_role_name] = useState("");

  //Stocker les erreur
  const [error_message, setError_message] = useState({
    user_role_name: "",
  });

  //les fields requierd
  const required_input = "user_role_name";

  const [error_list, setError_list] = useState([]);

  //handle scroll to top on errors
  const modalBodyAddRole = useRef(null);
  //components functions
  const assignAllPermissionsToUserRole = async (alr_data = {}) => {
    try {
      let message = "";
      if (JSON.stringify(alr_data) !== "{}") {
        const url = `/api/user/accee-link-role/all`;
        const options = {
          method: "POST",
          body: JSON.stringify({ alr_data: alr_data }),
        };
        const { loading, data, error } = await FetchData(url, options);
        if (data.status === 200) {
          message = `Toutes les permissions ont été assignées au nouveau profil!`;
          console.log(message);
        } else {
          message = data.error;
          console.error(data.error);
        }
      } else {
        message = "Les données du profil sont érronés!";
        console.error(
          `Erreur lors de l'assignation de toutes les permissions à un profil : \n ${message}`
        );
      }
    } catch (error) {
      console.error(
        `Erreur lors de l'assignation de toutes les permissions à un profil : \n ${error.message}`
      );
    }
  };

  const handleAddRole = async (e, role) => {
    e.preventDefault();
    try {
      const { loading, data, errors } = await FetchData(
        "/api/user/user-role/",
        {
          method: "POST",
          body: JSON.stringify(role),
        }
      );
      if (data.status === 200) {
        const alr_data = {
          alr_user_role_id: data.data.user_role_id,
          alr_user_id_create: data.data.user_role_user_id_create,
          alr_user_id_update: data.data.user_role_user_id_update,
        };
        assignAllPermissionsToUserRole(alr_data);
        Swal.fire({
          title: "Succès",
          text: "Création du profil effectuée.",
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        getUsers();
        handleCloseModal();
        setError_message({
          user_role_name: "",
        });
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(
        "\n\nErreur lors de la création du profil:{\n",
        error,
        "\n}\n"
      );
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function resetError() {
    handleCloseModal();
    setError_message({
      user_role_name: "",
    });
    setError_list([]);
  }

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }

    return isEmpty;
  }

  const testInputValueOnSubmit = (e) => {
    e.preventDefault();

    const inputs = new FormData(formRef.current);
    const errorListLabel = {
      user_role_name: "Nom du profil",
    };
    let newErrorList = [];
    let errors = error_message;
    let validForm = true;
    for (const key of inputs.keys()) {
      // client_bsl_num
      if (inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        newErrorList.push(errorListLabel[key]);
        validForm = false;
      }
    }
    setError_list(newErrorList);
    setError_message(errors);
    if (validForm) {
      let new_user_role = {
        user_role_name: user_role_name,
        user_role_active: 1,
        user_role_user_id_create: connectedUser?.id,
        user_role_user_id_update: connectedUser?.id,
      };
      handleAddRole(e, new_user_role);
    } else {
      modalBodyAddRole.current.scrollTop = 0;
    }
  };

  return (
    <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
      <div className="modal-header container-fluid">
        <h1 className="modal-title fs-5 ms-2" id={modalData?.modalId + "Label"}>
          {"Nouveau profil"}
          <span className="badge input-label-size bg-primary-subtle text-primary-emphasis fw-light ms-2">
            {"Nouveau"}
          </span>
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={resetError}
        ></button>
      </div>
      <Form
        formData={{
          type: "form",
          id: "updateRole",
          formMethod: "post",
          formAction: "#",
          formClass: "",
        }}
        submitFunction={testInputValueOnSubmit}
        formRef={formRef}
      >
        <div ref={modalBodyAddRole} className="modal-body p-0">
          {error_list.length > 0 ? <ErrorList errorList={error_list} /> : null}
          <FormSection
            formSectionData={{
              type: "section",
              title: "Profil",
              sectionClass: "border-bottom",
            }}
          >
            <div className="d-flex flex-row">
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="user_role_name"
                labelClass=""
                label="Nom du profil"
                inputClass="custom-input-colors"
                placeholder="Nom du profil"
                isRequired={true}
                isDisabled={false}
                errorMessage={error_message["user_role_name"]}
                value={user_role_name}
                onChange={(e) => set_user_role_name(e)}
              />
            </div>
          </FormSection>
        </div>
        <div
          className={
            "d-flex justify-content-end mt-2 border-top-0 modal-footer"
          }
        >
          <FormButton
            buttonType="button"
            buttonValue="Annuler"
            buttonClass="btn text-secondary input-label-size me-2"
            hasIcon={true}
            iconClass="far fa-times-circle me-2 bg-light"
            closeModal={true}
            buttonOnClick={resetError}
          />
          <FormButton
            buttonType="submit"
            buttonValue="Valider"
            buttonClass="btn green-button-bsl input-label-size me-2"
            hasIcon={true}
            iconClass="fas fa-check me-2"
            closeModal={false}
          />
        </div>
      </Form>
    </ModalForAddNew>
  );
};

export default AddRole;
