/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ModalForAddNew from "../../ModalForAddNew";
import Form from "../../../Form/Form";
import FormButton from "../../../Form/FormButton";
import FormSection from "../../../Form/FormSection";
import TextArea from "../../../Form/TextArea";
import "../../../../styles/Pages/login.css";
import "../../../../styles/Form/input.css";
import ErrorList from "../../../Form/ErrorList";
import CheckBox from "../../../Form/CheckBox";
import FormInput from "../../../Form/FormInput";
import "../../../..//styles/Tables/tableContent.css";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import validator from "validator";
import DisplayCurrentClientGeneral from "./DisplayCurrentClientGeneral";
import DisplayCurrentClientDevisCommande from "./DisplayCurrentClientDevisCommande";
import TabPane from "../../../Form/TabPane";
import DropdownButton from "../../../Form/DropdownButton";
import ListItems from "../../../Form/ListItems";
import SelectBoxSearch from "../../../Form/SelectBoxSearch";
import ShowModalinvoice from "./InvoiceClient";
import { useAccess } from "../../../../hooks/useAccess";
import { SearchCity } from "../../../../hooks/SearchCity";

const AddClient = ({
  modalData = {},
  isShowModal = false,
  closeModal = function () {},
  handleFetchData = function () {},
  currentClient = null,
  data_country = [],
  handleCurrentClient = (e) => {},
  clientStatus = [],
  whyShown = 0,
  invoice = {},
}) => {
  const formRef = useRef(null);

  //Permissions
  const can_change_client_status = useAccess(9);
  const can_send_invoice = useAccess(12);
  const can_see_requests_from_client = useAccess(58);
  const can_see_commands_from_client = useAccess(59);

  //All form fields states
  //General section
  const [client_id, setClient_id] = useState("");

  const [client_bsl_num, setClient_bsl_num] = useState("");

  const [client_company_name, setClient_company_name] = useState("");

  const [client_country, setClient_country] = useState("");

  const [client_zipcode, setClient_zipcode] = useState("");

  const [client_city, setClient_city] = useState("");

  const [client_adress_1, setClient_adress_1] = useState("");

  const [client_adress_2, setClient_adress_2] = useState("");

  const [client_adress_3, setClient_adress_3] = useState("");

  const [client_tva_num, setClient_tva_num] = useState("");

  const [client_cost_center, setClient_cost_center] = useState("");

  //Branches section
  const [cb_name, setCb_name] = useState("");

  //Commentaire section
  const [client_comment, setClient_comment] = useState("");

  //Contact section
  const [cc_is_devis_commande, setCc_is_devis_commande] = useState(false);

  const [cc_name, setCc_name] = useState("");

  const [cc_post, setCc_post] = useState("");

  const [cc_email, setCc_email] = useState("");

  const [cc_country, setCc_country] = useState("");

  const [cc_zipcode, setCc_zipcode] = useState("");

  const [cc_city, setCc_city] = useState("");

  const [cc_adress_1, setCc_adress_1] = useState("");

  const [cc_adress_2, setCc_adress_2] = useState("");

  const [cc_adress_3, setCc_adress_3] = useState("");

  const [cc_phone_1, setCc_phone_1] = useState("");

  const [cc_phone_2, setCc_phone_2] = useState("");

  const [cc_fax, setCc_fax] = useState("");

  const [cc_is_facturation_adress, setCc_is_facturation_adress] =
    useState(false);

  const [cc_data_contact, setCc_data_contact] = useState([]);

  const [cc_id, setCc_id] = useState(0);

  const [cc_branch_index, setCc_branch_index] = useState(-1);

  const userCookie = Cookies.get("userData");
  const userData = JSON.parse(
    typeof userCookie === "undefined" ? '{"id":0}' : Cookies.get("userData")
  );

  //carousel progress indicator
  const [carouselItems, setCarouselItems] = useState([
    {
      isActive: true,
      indicatorPosition: 25,
      itemText: "",
    },
    {
      isActive: false,
      indicatorPosition: 75,
      itemText: "",
    },
  ]);

  //carousel active page state
  const [activeItem, setActiveItem] = useState(0);

  // handle if the form just display user info or is a form for insert / update
  const [isUpdateOn, setIsUpdateOn] = useState(false);

  useEffect(() => {
    if (currentClient != null) {
      setClient_id(currentClient?.client_id);
      setClient_bsl_num(currentClient?.client_bsl_num);
      setClient_company_name(currentClient?.client_company_name);
      setClient_country(currentClient?.client_country);
      setClient_zipcode(currentClient?.client_zipcode);
      setClient_city(currentClient?.client_city);
      setClient_adress_1(currentClient?.client_adress_1);
      setClient_adress_2(currentClient?.client_adress_2);
      setClient_adress_3(currentClient?.client_adress_3);
      setClient_tva_num(currentClient?.client_tva_num);
      setClient_cost_center(currentClient?.client_cost_center);
      setClient_comment(currentClient?.client_comment);
      setData_branch(
        currentClient?.data_branch != null ? currentClient?.data_branch : []
      );
      setCc_data_contact(currentClient?.data_contact);
      setIsContactFormShow("CancelAddNewContact");
    } else {
      setClient_id("");
      setClient_bsl_num("");
      setClient_company_name("");
      setClient_country(undefined);
      setClient_zipcode("");
      setClient_city("");
      setClient_adress_1("");
      setClient_adress_2("");
      setClient_adress_3("");
      setClient_tva_num("");
      setClient_cost_center("");
      setClient_comment("");
      setData_branch([]);
      setCc_data_contact([]);
      setIsContactFormShow("AddContact");
      updateContactFormField(-1);
    }
  }, [currentClient]);

  const cc_table_keys = [
    "cc_name",
    "cc_post",
    "cc_email",
    "cc_phone",
    "cc_created_at",
    "cc_update",
    "cc_delete",
  ];

  const cc_table_headers = {
    cc_name: "Nom prénom",
    cc_post: "Poste",
    cc_email: "Email",
    cc_phone: "Téléphone",
    cc_created_at: "Date d'ajout",
    cc_update: "",
    cc_delete: "",
  };

  const [error_list, setError_list] = useState([]);

  const [isContactFormShow, setIsContactFormShow] = useState("AddContact");

  const [data_branch, setData_branch] = useState([]);

  const [error_message, setError_message] = useState({
    client_bsl_num: "",
    client_company_name: "",
    client_country: "",
    client_zipcode: "",
    client_city: "",
    client_adress_1: "",
    cc_name: "",
    cc_email: "",
    cc_country: "",
    cc_zipcode: "",
    cc_city: "",
    cc_adress_1: "",
    // cc_facturation_adress: "",
  });

  const required_input = [
    "client_bsl_num",
    "client_company_name",
    "client_country",
    "client_zipcode",
    "client_city",
    "client_adress_1",
  ];

  //handle scroll to top on errors
  const modalBodyAddClient = useRef(null);

  //handle fetch city name api call
  const city_controller = useRef();
  const city_client_controller = useRef();

  //component methods

  const onChangeEmail = (data) => {
    if (validator.isEmail(data) && data.length > 0) {
      setError_message((errors) => ({ ...errors, cc_email: "" }));
    }
    setCc_email(data);
  };

  const getLabelOfInput = (inputName, index = -1) => {
    let label = {
      client_bsl_num: "N° client BSL",
      client_company_name: "Nom de la société",
      client_country: "Pays",
      client_zipcode: "Code postal",
      client_city: "Ville",
      client_adress_1: "Adresse 1",
      cc_name: "Nom du contact",
      cc_country: "Pays",
      cc_zipcode: "Code postal",
      cc_city: "Ville",
      cc_adress_1: "Adresse 1",
    };

    if (inputName.includes("accordionItem_") && index > -1) {
      let branch = "accordionItem_" + index;
      label[inputName] = "Nom de la branche " + index;
    }
    return label[inputName];
  };

  const handleAddBranch = () => {
    let hasEmpty = false;
    let newBranches = data_branch.map((b_data, b_index) => {
      if (b_data.cb_name == "") {
        hasEmpty = true;
        b_data.error_message = "Champ non-vide";
      } else {
        b_data.error_message = "";
      }
      return b_data;
    });
    if (!hasEmpty) {
      newBranches.push({
        cb_id: null,
        cb_name: "",
        cb_id_client: client_id,
        num_company: "",
        cb_user_id_create: userData?.id,
        cb_user_id_update: userData?.id,
        error_message: "Champ non-vide",
        show_content: false,
        contact: [],
      });
      newBranches = newBranches.map((b_data, b_i) => {
        b_data.show_content = b_i == newBranches.length - 1;
        return b_data;
      });
    }
    setData_branch(newBranches);
  };

  function removeBranch(cb_i) {
    setData_branch((v) => v.filter((d, i) => i != cb_i));
  }

  // ============= function to handle next carousel transition =================
  const nextCarouselItem = () => {
    let form = new FormData(formRef.current);
    let newErrorList = [];
    let validForm = true;
    let errors = error_message;
    let currentRequired = required_input.filter((d) => !d.includes("cc_"));

    for (const key of form.keys()) {
      if (currentRequired.includes(key) && inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        newErrorList.push(getLabelOfInput(key));
        validForm = false;
      } else {
        errors[key] = "";
      }
    }
    if (data_branch.length > 0) {
      data_branch.map((b_d, b_i) => {
        if (b_d.cb_name == "") {
          b_d.error_message = "Champ non-vide";
          newErrorList.push(getLabelOfInput(`accordionItem_${b_i}`, b_i + 1));
          validForm = false;
        } else {
          b_d.error_message = "";
        }
        return b_d;
      });
    }
    setError_list(newErrorList);
    setError_message(errors);
    if (validForm) {
      let nextItem = activeItem;
      nextItem += 1;
      if (carouselItems.length <= nextItem) {
        nextItem = 0;
      }
      setActiveItem(nextItem);
      setCarouselItems((carousel) =>
        carousel.map((d, i) => {
          if (i == nextItem) {
            d.isActive = true;
          } else {
            d.isActive = false;
          }
          return d;
        })
      );
    } else {
      modalBodyAddClient.current.scrollTop = 0;
    }
  };

  // ============= function to handle previous carousel transition =================
  const previousCarouselItem = () => {
    let previousItem = activeItem;
    previousItem -= 1;
    if (previousItem < 0) {
      previousItem = carouselItems.length - 1;
    }
    setActiveItem(previousItem);
    setCarouselItems((carousel) =>
      carousel.map((d, i) => {
        if (i == previousItem) {
          d.isActive = true;
        } else {
          d.isActive = false;
        }
        return d;
      })
    );
  };

  //==================== function to add / update client ============================
  const handleSubmitClient = async (e) => {
    e.preventDefault();
    let form = new FormData(formRef.current);
    let newErrorList = [];

    let validForm = true;
    let errors = error_message;
    let currentRequired = required_input;
    if (data_branch.length > 0) {
      data_branch.map((b_d, b_i) => {
        currentRequired.push("accordionItem_" + b_i);
        return b_d;
      });
    }

    if (
      isContactFormShow === "UpdateContact" ||
      isContactFormShow === "AddNewContact"
    ) {
      if (cc_is_facturation_adress == true) {
        if (cc_name == "") {
          errors["cc_name"] = "Champ non-vide";
          newErrorList.push(getLabelOfInput("cc_name"));
          validForm = false;
        } else {
          errors["cc_name"] = "";
        }

        if (cc_country == "") {
          errors["cc_country"] = "Champ non-vide";
          newErrorList.push(getLabelOfInput("cc_country"));
          validForm = false;
        } else {
          errors["cc_country"] = "";
        }

        if (cc_zipcode == "") {
          errors["cc_zipcode"] = "Champ non-vide";
          newErrorList.push(getLabelOfInput("cc_zipcode"));
          validForm = false;
        } else {
          errors["cc_zipcode"] = "";
        }

        if (cc_city == "") {
          errors["cc_city"] = "Champ non-vide";
          newErrorList.push(getLabelOfInput("cc_city"));
          validForm = false;
        } else if (cc_city.trim() == "ville non-trouvé") {
          errors["cc_city"] = "Champ non-valide";
        } else {
          errors["cc_city"] = "";
        }

        if (cc_adress_1 == "") {
          errors["cc_adress_1"] = "Champ non-vide";
          newErrorList.push(getLabelOfInput("cc_adress_1"));
          validForm = false;
        } else {
          errors["cc_adress_1"] = "";
        }
      } else {
        if (cc_name == "") {
          errors["cc_name"] = "Champ non-vide";
          newErrorList.push(getLabelOfInput("cc_name"));
          validForm = false;
        } else {
          errors["cc_name"] = "";
        }
      }
      if (
        isContactFormShow === "AddNewContact" ||
        isContactFormShow === "UpdateContact"
      ) {
        validForm = false;
        newErrorList.push(
          isContactFormShow === "AddNewContact"
            ? "Ajout du contact non-validé"
            : "Modification du contact non-validé"
        );
      }
    }

    for (let i = 0; i < data_branch.length; i++) {
      if (!data_branch[i].contact || data_branch[i].contact?.length < 1) {
        validForm = false;
        newErrorList.push("Contact vide!");
      }
    }

    if (data_branch.length < 1) {
      validForm = false;
      newErrorList.push("Branche vide!");
    }

    if (!validator.isEmail(cc_email) && cc_email.length > 0) {
      validForm = false;
      errors["cc_email"] = "E-mail invalide";
      newErrorList.push("E-mail");
    }

    for (const key of form.keys()) {
      if (currentRequired.includes(key) && inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        newErrorList.push(
          key.includes("accordionItem_")
            ? getLabelOfInput(key, parseInt(key.split[1]) + 1)
            : getLabelOfInput(key)
        );
        validForm = false;
      }
      if (currentRequired.includes(key) && !inputIsEmptyError(key)) {
        errors[key] = "";
        errors = { ...errors, key: "" };
      }
    }
    if (!validator.isEmail(form.get("cc_email")) && form.get("cc_email") > 0) {
      validForm = false;
      errors["cc_email"] = "E-mail invalide";
      newErrorList.push("E-mail");
    }

    setError_list(newErrorList);
    setError_message(errors);
    if (validForm) {
      let branches = data_branch.filter((d) => d.cb_name != "");
      let new_client = {
        client_id_statut: 1,
        client_bsl_num: client_bsl_num,
        client_company_name: client_company_name,
        client_country: client_country,
        client_zipcode: client_zipcode,
        client_city: client_city,
        client_adress_1: client_adress_1,
        client_adress_2: client_adress_2,
        client_adress_3: client_adress_3,
        client_tva_num: client_tva_num,
        client_cost_center: client_cost_center,
        client_comment: client_comment,
        client_user_id_update: userData.id,
        client_user_id_create: userData.id,
        data_branch: branches,
      };
      try {
        let url = "";
        let option = {};
        let success_message = "";
        if (whyShown < 2) {
          url = "/api/client";
          option = { method: "POST", body: JSON.stringify(new_client) };
          success_message = "Ajout du client réussi!";
        } else {
          if (currentClient != null) {
            new_client.client_id_statut = currentClient.client_id_statut;
            url = `/api/client/${currentClient.client_id}`;
            option = { method: "PUT", body: JSON.stringify(new_client) };
          }
          success_message = "Modification du client réussi!";
        }
        const { loading, data, errors } = await FetchData(url, option);
        if (data.status === 200) {
          Swal.fire({
            title: "Succès",
            text: success_message,
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          closeModal();
          handleFetchData();
        } else {
          Swal.fire({
            title: "Erreur",
            text: data.error,
            icon: "error",
            confirmButtonColor: "#0e3feb",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {}
    } else {
      modalBodyAddClient.current.scrollTop = 0;
    }
  };

  //================= function to check if required form inputs are empty ===============
  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "") {
        isEmpty = true;
      }
    }
    return isEmpty;
  }

  //======== function to handle branch input fields change =========================
  const handleInputChange = (e) => {
    const index = parseInt(e.target.id.split("_")[1]);
    const data_to_update = data_branch.map((d, i) => {
      let res = d;
      if (i == index) {
        res = {
          ...res,
          cb_name: e.target.value,
          error_message:
            e.target.value.trim().length > 0 ? "" : "Champ non-vide",
        };
      }
      return res;
    });
    setData_branch(data_to_update);
  };

  const handleNumCompanyChange = (e) => {
    const index = parseInt(e.target.id.split("_")[1]);
    const data_to_update = data_branch.map((d, i) => {
      let res = d;
      if (i == index) {
        res = {
          ...res,
          num_company: e.target.value,
        };
      }
      return res;
    });
    setData_branch(data_to_update);
  };
  // ================== function to handle contact form states =========================
  const handleContactForm = (contactFormState, cb_index = 0) => {
    switch (true) {
      case contactFormState === "CancelAddNewContact":
        setIsContactFormShow(contactFormState);
        setData_branch((branch) => {
          branch[cb_index].contact = branch[cb_index].contact.filter(
            (d) => d.cc_name != null && d.cc_name != ""
          );
          return branch;
        });
        break;
      case contactFormState === "AddNewContact":
        setIsContactFormShow(contactFormState);
        let branch = data_branch;
        if (branch[cb_index].contact && branch[cb_index].contact.length > 0) {
          branch[cb_index].contact = [
            ...branch[cb_index].contact,
            {
              cc_name: "",
              cc_post: "",
              cc_email: "",
              cc_country: "",
              cc_zipcode: "",
              cc_city: "",
              cc_adress_1: "",
              cc_adress_2: "",
              cc_adress_3: "",
              cc_phone_1: "",
              cc_phone_2: "",
              cc_fax: "",
              cc_is_devis_commande: false,
              cc_is_facturation_adress: false,
              cc_user_id_create: null,
              cc_id_client: null,
              cc_user_id_update: null,
              cc_created_at: null,
              cc_update: "update",
              cc_delete: "delete",
            },
          ];
        } else {
          branch[cb_index].contact = [
            {
              cc_name: "",
              cc_post: "",
              cc_email: "",
              cc_country: "",
              cc_zipcode: "",
              cc_city: "",
              cc_adress_1: "",
              cc_adress_2: "",
              cc_adress_3: "",
              cc_phone_1: "",
              cc_phone_2: "",
              cc_fax: "",
              cc_is_devis_commande: false,
              cc_is_facturation_adress: false,
              cc_user_id_create: null,
              cc_id_client: null,
              cc_user_id_update: null,
              cc_created_at: null,
              cc_update: "update",
              cc_delete: "delete",
            },
          ];
        }
        setData_branch(branch);
        updateContactFormField(cb_index, -1);
        break;
      case contactFormState === "UpdateContact" ||
        contactFormState === "AddContact":
        setError_list([]);
        setError_message((errors) => {
          for (let error in errors) {
            errors[error] = "";
          }
          return errors;
        });
        setIsContactFormShow(contactFormState);
        break;
      default:
    }
  };

  // ===================== function to handle input field for contact section =====================
  function updateContactFormField(cb_index = 0, index) {
    if (index > -1) {
      setCc_is_devis_commande(
        data_branch[cb_index].contact[index].cc_is_devis_commande
      );
      setCc_name(data_branch[cb_index].contact[index].cc_name);
      setCc_post(data_branch[cb_index].contact[index].cc_post);
      setCc_email(data_branch[cb_index].contact[index].cc_email);
      setCc_country(data_branch[cb_index].contact[index].cc_country);
      setCc_zipcode(data_branch[cb_index].contact[index].cc_zipcode);
      setCc_city(data_branch[cb_index].contact[index].cc_city);
      setCc_adress_1(data_branch[cb_index].contact[index].cc_adress_1);
      setCc_adress_2(data_branch[cb_index].contact[index].cc_adress_2);
      setCc_adress_3(data_branch[cb_index].contact[index].cc_adress_3);
      setCc_phone_1(data_branch[cb_index].contact[index].cc_phone_1);
      setCc_phone_2(data_branch[cb_index].contact[index].cc_phone_2);
      setCc_fax(data_branch[cb_index].contact[index].cc_fax);
      setCc_is_facturation_adress(
        data_branch[cb_index].contact[index].cc_is_facturation_adress
      );
    } else {
      setCc_is_devis_commande(false);
      setCc_name("");
      setCc_post("");
      setCc_email("");
      setCc_country("");
      setCc_zipcode("");
      setCc_city("");
      setCc_adress_1("");
      setCc_adress_2("");
      setCc_adress_3("");
      setCc_phone_1("");
      setCc_phone_2("");
      setCc_fax("");
      setCc_is_facturation_adress(false);
    }
    setCc_branch_index(cb_index);
  }

  // =============== function which handles contact inputs error messages =======================
  const verifyContacForm = () => {
    let newErrorList = [];
    let errors = error_message;
    let validForm = true;
    if (cc_is_facturation_adress == true) {
      if (cc_name == "") {
        errors["cc_name"] = "Champ non-vide";
        newErrorList.push(getLabelOfInput("cc_name"));
        validForm = false;
      } else {
        errors["cc_name"] = "";
      }

      if (cc_country == "") {
        errors["cc_country"] = "Champ non-vide";
        newErrorList.push(getLabelOfInput("cc_country"));
        validForm = false;
      } else {
        errors["cc_country"] = "";
      }

      if (cc_zipcode == "") {
        errors["cc_zipcode"] = "Champ non-vide";
        newErrorList.push(getLabelOfInput("cc_zipcode"));
        validForm = false;
      } else {
        errors["cc_zipcode"] = "";
      }

      if (cc_city == "") {
        errors["cc_city"] = "Champ non-vide";
        newErrorList.push(getLabelOfInput("cc_city"));
        validForm = false;
      } else {
        errors["cc_city"] = "";
      }

      if (cc_adress_1 == "") {
        errors["cc_adress_1"] = "Champ non-vide";
        newErrorList.push(getLabelOfInput("cc_adress_1"));
        validForm = false;
      } else {
        errors["cc_adress_1"] = "";
      }
    } else {
      if (cc_name == "") {
        errors["cc_name"] = "Champ non-vide";
        newErrorList.push(getLabelOfInput("cc_name"));
        validForm = false;
      } else {
        errors["cc_name"] = "";
      }
    }
    if (!validator.isEmail(cc_email) && cc_email.length > 0) {
      validForm = false;
      errors["cc_email"] = "E-mail invalide";
      newErrorList.push("E-mail");
    }

    setError_list(newErrorList);
    setError_message(errors);
    return validForm;
  };

  // ================ function which handles add new contact ==================================
  const submitContact = () => {
    if (verifyContacForm() == true) {
      let date_c = new Date();
      const contact = {
        cc_name: cc_name,
        cc_post: cc_post,
        cc_email: cc_email,
        cc_country: cc_country != "" ? parseInt(cc_country) : null,
        cc_zipcode: cc_zipcode,
        cc_city: cc_city,
        cc_adress_1: cc_adress_1,
        cc_adress_2: cc_adress_2,
        cc_adress_3: cc_adress_3,
        cc_phone_1: cc_phone_1,
        cc_phone_2: cc_phone_2,
        cc_fax: cc_fax,
        cc_is_devis_commande: cc_is_devis_commande,
        cc_is_facturation_adress: cc_is_facturation_adress,
        cc_user_id_create: userData?.id,
        cc_id_client: client_id,
        cc_user_id_update: userData?.id,
        cc_update: "update",
        cc_delete: "delete",
      };
      if (isContactFormShow === "AddNewContact") {
        setData_branch((branch) => {
          branch[cc_branch_index].contact = branch[cc_branch_index].contact.map(
            (d, i) => {
              let update = branch[cc_branch_index].contact.length - 1 == i;
              return update ? contact : d;
            }
          );
          return branch;
        });
      } else if (isContactFormShow === "UpdateContact") {
        contact.cc_id = data_branch[cc_branch_index].contact[cc_id].cc_id;
        setData_branch((branch) => {
          branch[cc_branch_index].contact = branch[cc_branch_index].contact.map(
            (d, i) => {
              let update = i == cc_id;
              return update ? contact : d;
            }
          );
          return branch;
        });
      }

      handleContactForm("AddContact");
    } else {
      modalBodyAddClient.current.scrollTop = 0;
    }
  };

  // ============= function that transfert a contact to the contact form section for update ===============
  const handleUpdateContact = (branch_index, updatedContactIndex) => {
    handleContactForm("UpdateContact");
    setCc_id(updatedContactIndex);
    updateContactFormField(branch_index, updatedContactIndex);
  };

  // =============== function that delete a contact in the contact table in the form ===================
  const handleDeleteContact = (branch_index, contactIndex) => {
    let contactNumber = data_branch[branch_index].contact.length;
    setData_branch((v) =>
      v.map((d, i) => {
        if (i === branch_index) {
          return {
            ...d,
            contact: d.contact.filter((c, j) => j !== contactIndex),
          };
        }
        return d;
      })
    );
    if (contactNumber == 1) {
      handleContactForm("AddNewContact");
    } else {
      handleContactForm("CancelAddNewContact");
    }
  };

  // Functions for CSS class of the form
  function colorDropdown(clientStatutId) {
    let color = "";
    switch (true) {
      case clientStatutId == 1:
        color = "bg-success-subtle text-success ";
        break;
      case clientStatutId == 2:
        color = "bg-danger-subtle text-danger ";
        break;
    }
    return color;
  }

  function colorItem(clientStatutId) {
    let color = "";
    switch (true) {
      case clientStatutId == 1:
        color =
          " text-success " +
          (currentClient?.client_id_statut == clientStatutId
            ? "bg-sucess-subtle "
            : "bg-white ");
        break;
      case clientStatutId == 2:
        color =
          " text-danger " +
          (currentClient?.client_id_statut == clientStatutId
            ? "bg-danger-subtle "
            : "bg-white ");
        break;
      default:
        color = " bg-primary-subtle text-primary-emphasis";
    }
    return color;
  }

  //
  function handleClientStatus(e) {
    handleCurrentClient((v) => ({ ...v, client_id_statut: e.target.value }));
  }

  function changeToItem(itemIndex) {
    setCarouselItems((carouselItems) => {
      let newCarouselItems = carouselItems.map((item, index) => {
        if (index == itemIndex) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      });
      return newCarouselItems;
    });

    setActiveItem(itemIndex);
  }

  function resetForm() {
    setClient_id("");
    setClient_bsl_num("");
    setClient_company_name("");
    setClient_country("");
    setClient_zipcode("");
    setClient_city("");
    setClient_adress_1("");
    setClient_adress_2("");
    setClient_adress_3("");
    setClient_tva_num("");
    setClient_cost_center("");
    setClient_comment("");
    setData_branch([]);
    setCc_data_contact([]);
    setIsContactFormShow("AddContact");
    updateContactFormField(-1);
    setError_list([]);
    setError_message((errors) => {
      for (let error in errors) {
        errors[error] = "";
      }
      return errors;
    });
    changeToItem(0);
  }

  function handleBranchesAccordion(branch_index) {
    let newBranches = data_branch;
    newBranches = newBranches.map((b_data, b_i) => {
      b_data.show_content = b_i == branch_index;
      return b_data;
    });
    setData_branch(newBranches);
  }

  //================= function
  function seeThisDropDownItem(dropDownItem) {
    const dropdownItem = {
      demandes: "demandes",
      commandes: "commandes",
      factures: "factures",
    };
  }

  //useEffect to handle if the form is for update or create Sous traitant
  useEffect(() => {
    switch (true) {
      case whyShown == 1:
        resetForm();
        setIsUpdateOn(true);
        setCarouselItems((items) => {
          items[0].itemText = "Formulaire";
          items[1].itemText = "Branches";
          return items;
        });
        break;
      case whyShown == 2:
        if (currentClient != null) {
          setError_list([]);
          setError_message((errors) => {
            for (let error in errors) {
              errors[error] = "";
            }
            return errors;
          });
          changeToItem(0);
          setIsUpdateOn(false);
          setCarouselItems((items) => {
            items[0].itemText = "Général";
            items[1].itemText = "Branches";
            return items;
          });
        }
      default:
    }
  }, [whyShown]);

  const search_city = (
    value,
    setNewValue = () => {},
    target_country = 0,
    target_zipcode = "",
    city_owner = "client"
  ) => {
    switch (city_owner) {
      case "client":
        city_client_controller.current = SearchCity(
          target_country,
          target_zipcode,
          setClient_city,
          city_client_controller.current,
          data_country
        );
        break;
      case "contact":
        city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          setCc_city,
          city_controller.current,
          data_country
        );
        break;
    }

    setNewValue(value);
  };

  return (
    <Form
      submitFunction={handleSubmitClient}
      formData={{
        type: "form",
        id: "addClient",
        formClass: "",
      }}
      formRef={formRef}
    >
      <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
        <div
          className="modal-header container-fluid d-flex flex-column"
          style={whyShown < 2 ? { minHeight: "15%" } : { minHeight: "15%" }}
        >
          <div
            className="d-flex flex-row w-100 align-items-center "
            style={{ height: "50%" }}
          >
            <div className="d-flex justify-content-start align-items-center w-50 h-100">
              <h1
                className="modal-title fs-5"
                id={modalData?.modalId + "Label"}
              >
                {whyShown > 1
                  ? currentClient.client_company_name
                  : modalData?.title}
                {whyShown > 1 ? (
                  <div className="d-inline-block">
                    {can_change_client_status && isUpdateOn ? (
                      <select
                        className={
                          "badge input-label-size fw-light border-0 ms-2 " +
                          colorDropdown(currentClient.client_id_statut)
                        }
                        onChange={(e) => handleClientStatus(e)}
                        value={currentClient.client_id_statut}
                      >
                        {clientStatus.map((cs_data, cs_index) => (
                          <option
                            className={
                              "ps-2 pe-2 " +
                              colorItem(cs_data.client_statut_id) +
                              (currentClient.client_id_statut ==
                              cs_data.client_statut_id
                                ? " fw-bold "
                                : " ")
                            }
                            key={"statut_" + cs_index}
                            value={cs_data.client_statut_id}
                          >
                            {cs_data.client_statut_name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span
                        className={
                          "badge input-label-size fw-light border-0 ms-2 " +
                          colorDropdown(currentClient.client_id_statut)
                        }
                        value={currentClient.client_id_statut}
                      >
                        {
                          clientStatus.filter(
                            (status) =>
                              status.client_statut_id ===
                              currentClient.client_id_statut
                          )[0].client_statut_name
                        }
                      </span>
                    )}

                    {can_send_invoice && (
                      <ShowModalinvoice invoice_data={invoice} />
                    )}
                  </div>
                ) : (
                  <span className="badge input-label-size bg-primary-subtle text-primary-emphasis fw-light ms-2">
                    {"Nouveau"}
                  </span>
                )}
              </h1>
            </div>
            <div className="d-flex justify-content-end align-items-center w-50 h-100">
              {isUpdateOn == false &&
                (can_see_requests_from_client ||
                  can_see_commands_from_client) && (
                  <DropdownButton>
                    {can_see_requests_from_client && (
                      <ListItems>
                        <a
                          className="dropdown-item ms-3"
                          href="#"
                          onClick={() => seeThisDropDownItem("demandes")}
                        >
                          Voir demandes
                          <i className="fas fa-file-circle-check ms-2"></i>
                        </a>
                      </ListItems>
                    )}
                    {can_see_commands_from_client && (
                      <ListItems>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => seeThisDropDownItem("commandes")}
                        >
                          Voir Commandes
                          <i className="fas fa-file-import ms-2"></i>
                        </a>
                      </ListItems>
                    )}
                  </DropdownButton>
                )}
              <button
                type="button"
                className="btn-close ms-2 me-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </div>
          <div className="w-100 mt-1 mb-0" style={{ height: "50%" }}>
            {whyShown < 2 ? (
              <>
                <div
                  className="d-flex justify-content-evenly z-2 position-absolute"
                  style={{ width: "95%" }}
                >
                  <div className="d-flex flex-column align-items-center w-50">
                    <div
                      className={`active badge rounded-circle input-text-size ${
                        carouselItems[0].isActive == true
                          ? " bg-primary text-white border border-light border-5 border-opacity-75"
                          : "bg-primary-subtle text-primary-emphasis border border-light border-5 border-opacity-0"
                      }`}
                      style={{
                        width: "35px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    >
                      1
                    </div>
                    <span className="text-muted" style={{ fontSize: "10px" }}>
                      {carouselItems[0].itemText}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center w-50">
                    <div
                      className={`active badge rounded-circle opacity-100 input-text-size ${
                        carouselItems[1].isActive == true
                          ? " bg-primary text-white border border-light border-5 border-opacity-75"
                          : " bg-primary-subtle text-primary-emphasis border border-light border-5 border-opacity-0"
                      }`}
                      style={{
                        width: "35px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    >
                      <div className="">2</div>
                    </div>
                    <span
                      className="text-muted"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {carouselItems[1].itemText}
                    </span>
                  </div>
                </div>
                <div
                  className="progress mb-0"
                  role="progressbar"
                  aria-label="carouselProgress"
                  style={{ height: "2px", marginTop: "17px" }}
                >
                  <div
                    style={{ width: "100%" }}
                    className={`progress-bar w-${carouselItems[activeItem].indicatorPosition}`}
                  ></div>
                </div>
              </>
            ) : (
              <TabPane
                carouselItems={carouselItems}
                changeCarouselItems={changeToItem}
              />
            )}
          </div>
        </div>
        {isUpdateOn == true ? (
          <>
            {/* ===================Modal body start here ==================== */}
            <div
              ref={modalBodyAddClient}
              className="modal-body p-0"
              style={{ minHeight: "73%" }}
            >
              <ErrorList errorList={error_list} />
              <div
                id="formUpdatableCarousel"
                className="carousel slide carousel-fade"
              >
                <div className="carousel-inner">
                  <div
                    className={`carousel-item ${
                      carouselItems[0].isActive == true ? "active" : ""
                    }`}
                  >
                    {/* ============================== General Section ============================================= */}
                    <FormSection
                      formSectionData={{
                        title: "Général",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <div className="d-flex flex-row">
                        <FormInput
                          type={"text"}
                          blockClass={"mb-3 me-2 col"}
                          id={"client_id"}
                          label="N° client"
                          placeholder={"N° client"}
                          value={client_id}
                          isRequired={false}
                          isDisabled={true}
                          errorMessage={""}
                        />
                        <FormInput
                          type={"text"}
                          blockClass={"mb-3 me-2 col"}
                          id={"client_bsl_num"}
                          label={"N° client BSL"}
                          placeholder={"N° client BSL"}
                          value={client_bsl_num}
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["client_bsl_num"]}
                          onChange={setClient_bsl_num}
                        />
                      </div>
                      <FormInput
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"client_company_name"}
                        label={"Nom de la société"}
                        placeholder={"Nom de la société"}
                        value={client_company_name}
                        isRequired={true}
                        isDisabled={false}
                        errorMessage={error_message["client_company_name"]}
                        onChange={setClient_company_name}
                      />
                      <div className="d-flex flex-row">
                        {whyShown > 1 ? (
                          <SelectBoxSearch
                            type="select"
                            blockClass="mb-3 me-2 col"
                            id="client_country"
                            labelClass=""
                            label="Pays"
                            selectClass="input-label-size "
                            isRequired={true}
                            defaultValue={currentClient?.client_country}
                            errorMessage={error_message["client_country"]}
                            selectOptions={data_country}
                            onChange={(e) =>
                              search_city(
                                e,
                                setClient_country,
                                e,
                                client_zipcode,
                                "client"
                              )
                            }
                            cle="country_id"
                            value="country_name"
                            placeholder="Pays"
                          />
                        ) : (
                          <SelectBoxSearch
                            type="select"
                            blockClass="mb-3 me-2 col"
                            id="client_country"
                            labelClass=""
                            label="Pays"
                            selectClass="input-label-size "
                            isRequired={true}
                            defaultValue={client_country}
                            errorMessage={error_message["client_country"]}
                            selectOptions={data_country}
                            onChange={(e) =>
                              search_city(
                                e,
                                setClient_country,
                                e,
                                client_zipcode,
                                "client"
                              )
                            }
                            cle="country_id"
                            value="country_name"
                            placeholder="Pays"
                          />
                        )}

                        <FormInput
                          type={"text"}
                          blockClass={"mb-3 me-2 col"}
                          id={"client_zipcode"}
                          label={"Code Postal"}
                          placeholder={"code Postal"}
                          value={client_zipcode}
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["client_zipcode"]}
                          onChange={(e) =>
                            search_city(
                              e,
                              setClient_zipcode,
                              client_country,
                              e,
                              "client"
                            )
                          }
                        />
                        <FormInput
                          type={"text"}
                          blockClass={"mb-3 me-2 col"}
                          id={"client_city"}
                          label={"Ville"}
                          placeholder={"Ville"}
                          value={client_city}
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["client_city"]}
                          onChange={setClient_city}
                        />
                      </div>
                      <FormInput
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"client_adress_1"}
                        label={"Adresse 1"}
                        placeholder={"Adresse 1"}
                        value={client_adress_1}
                        isRequired={true}
                        isDisabled={false}
                        errorMessage={error_message["client_adress_1"]}
                        onChange={setClient_adress_1}
                      />
                      <FormInput
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"client_adress_2"}
                        label={"Adresse 2"}
                        placeholder={"Adresse 2"}
                        value={client_adress_2}
                        isRequired={false}
                        isDisabled={false}
                        errorMessage={""}
                        onChange={setClient_adress_2}
                      />
                      <FormInput
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"client_adress_3"}
                        label={"Adresse 3"}
                        placeholder={"Adresse 3"}
                        value={client_adress_3}
                        isRequired={false}
                        isDisabled={false}
                        errorMessage={""}
                        onChange={setClient_adress_3}
                      />
                      <FormInput
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"client_tva_num"}
                        label={"N° TVA Intracommunautaire"}
                        placeholder={"N° TVA Intracommunautaire"}
                        value={client_tva_num}
                        isRequired={false}
                        isDisabled={false}
                        errorMessage={""}
                        onChange={setClient_tva_num}
                      />
                      <FormInput
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"client_cost_center"}
                        label={"Cost Center"}
                        placeholder={"Cost Center"}
                        value={client_cost_center}
                        isRequired={false}
                        isDisabled={false}
                        errorMessage={""}
                        onChange={setClient_cost_center}
                      />
                    </FormSection>
                    {/* ====================================== Commentaires section ================================== */}
                    <FormSection
                      formSectionData={{
                        title: "Commentaire",
                        sectionClass: "",
                      }}
                    >
                      <TextArea
                        id={"client_comment"}
                        name={"client_comment"}
                        rows={5}
                        label={"Commentaire"}
                        value={client_comment}
                        placeholder={"Commentaire"}
                        onChange={setClient_comment}
                      />
                    </FormSection>
                  </div>
                  <div
                    className={`carousel-item ${
                      carouselItems[1].isActive == true ? "active" : ""
                    }`}
                  >
                    {/* ============================ contact accordion =============================================== */}
                    <FormSection
                      formSectionData={{
                        title: "Branche",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          className="accordion h-100 w-100"
                          id="accordionContacts"
                        >
                          <div className="accordion-item border-0">
                            <div
                              id="contactOne"
                              className={
                                "accordion-collapse collapse " +
                                (isContactFormShow == "CancelAddNewContact" ||
                                isContactFormShow == "AddContact"
                                  ? "show "
                                  : "")
                              }
                            >
                              {/*  =============================== boutons ajouter nouveau contact ============================== */}
                              <FormButton
                                buttonType="button"
                                buttonValue="Ajouter"
                                buttonClass="btn btn-outline-primary input-label-size me-2 rounded rounded-1"
                                hasIcon={true}
                                iconClass="fas fa-plus me-2 "
                                buttonOnClick={handleAddBranch}
                              />
                              <div className="d-flex me-3 mt-3 mb-3">
                                <div
                                  className="accordion w-100"
                                  id="accordionBranches"
                                >
                                  {data_branch?.map((cb_data, cb_i) => (
                                    <div
                                      className="accordion-item show p-2"
                                      key={cb_i}
                                    >
                                      <div className="accordion-header input-group">
                                        <div className="d-flex flex-column col">
                                          <label
                                            className="form-label input-label-size position-relative "
                                            htmlFor="accordionItem1"
                                          >
                                            Nom de la branche {cb_i + 1}
                                            <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group">
                                            <input
                                              id={"accordionItem_" + cb_i}
                                              name={"accordionItem_" + cb_i}
                                              key={"0"}
                                              type="text"
                                              className={
                                                "form-control form-control-lg input-text-size border-end-0 " +
                                                (cb_data.cb_name == ""
                                                  ? "invalid-input-colors"
                                                  : "")
                                              }
                                              placeholder="Nom de la branche"
                                              value={cb_data.cb_name}
                                              onChange={handleInputChange}
                                            />
                                            <button
                                              className={
                                                "btn btn-transparent border-start-0 " +
                                                (cb_data.cb_name == ""
                                                  ? "invalid-input-colors"
                                                  : "")
                                              }
                                              type="button"
                                              style={{
                                                border:
                                                  "var(--bs-border-width) solid var(--bs-border-color)",
                                              }}
                                              onClick={() => {
                                                removeBranch(cb_i);
                                              }}
                                            >
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </div>
                                          {cb_data.cb_name == "" && (
                                            <span className="input-error-message">
                                              <i className="fa-solid fa-circle-exclamation text-danger me-2"></i>
                                              {cb_data.error_message}
                                            </span>
                                          )}
                                        </div>
                                        <div className="d-flex flex-column col ms-5 pe-1">
                                          <label
                                            className="form-label input-label-size position-relative "
                                            htmlFor={`numCompany_${cb_i}`}
                                          >
                                            Numéro de la société
                                          </label>
                                          <div className="">
                                            <input
                                              id={"numCompany_" + cb_i}
                                              name={"numCompany_" + cb_i}
                                              key={"0"}
                                              type="text"
                                              className={
                                                "form-control form-control-lg input-text-size border-1 "
                                              }
                                              placeholder="Numéro de la société"
                                              value={cb_data?.num_company}
                                              onChange={handleNumCompanyChange}
                                            />
                                          </div>
                                        </div>
                                        <button
                                          className={`accordion-button bg-white col shadow-none ${
                                            cb_data.show_content
                                              ? ""
                                              : " collapsed "
                                          }`}
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse_${
                                            cb_i + 1
                                          }`}
                                          aria-expanded="true"
                                          aria-controls={`#collapse_${
                                            cb_i + 1
                                          }`}
                                          onClick={() =>
                                            handleBranchesAccordion(cb_i)
                                          }
                                        ></button>
                                      </div>
                                      <div
                                        id={`collapse_${cb_i + 1}`}
                                        className={`accordion-collapse collapse ${
                                          cb_data.show_content ? "show" : ""
                                        }`}
                                      >
                                        <div className="accordion-body ps-0 pt-0 d-flex flex-column justify-content-end">
                                          <div className="mb-3 d-flex justify-content-end">
                                            <FormButton
                                              buttonType="button"
                                              buttonValue="Ajouter un contact"
                                              buttonClass="btn btn-outline-primary input-label-size me-2 mt-2 rounded rounded-1"
                                              hasIcon={true}
                                              iconClass="fas fa-plus me-2 "
                                              buttonOnClick={() =>
                                                handleContactForm(
                                                  "AddNewContact",
                                                  cb_i
                                                )
                                              }
                                            />
                                          </div>
                                          {/* ======================= Tableau de contacts ============================= */}
                                          {data_branch[cb_i].contact?.length <
                                            1 ?? true ? null : (
                                            <>
                                              <div className="d-flex justify-content-center">
                                                <table className="table bsl-table-global mb-0">
                                                  <thead>
                                                    <tr
                                                      className="table-active"
                                                      key={"headers"}
                                                    >
                                                      {cc_table_keys.map(
                                                        (key, index) => (
                                                          <th
                                                            key={
                                                              "index_" + index
                                                            }
                                                            className={
                                                              " input-text-size fw-bold " +
                                                              (cc_table_headers[
                                                                key
                                                              ] === ""
                                                                ? "w-delete-70"
                                                                : "")
                                                            }
                                                          >
                                                            {
                                                              cc_table_headers[
                                                                key
                                                              ]
                                                            }
                                                          </th>
                                                        )
                                                      )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {data_branch[
                                                      cb_i
                                                    ].contact?.map(
                                                      (item, i_c) => {
                                                        return (
                                                          <tr
                                                            className="table-active"
                                                            key={i_c}
                                                          >
                                                            {cc_table_keys.map(
                                                              (c_k, i_k) => (
                                                                <td
                                                                  key={i_k}
                                                                  className="align-middle text-nowrap "
                                                                  style={{
                                                                    overflow:
                                                                      "hidden",
                                                                    textOverflow:
                                                                      "ellipsis",
                                                                  }}
                                                                >
                                                                  {c_k ===
                                                                  "cc_phone" ? (
                                                                    item[
                                                                      "cc_phone_1"
                                                                    ] != "" ? (
                                                                      item[
                                                                        "cc_phone_1"
                                                                      ]
                                                                    ) : item[
                                                                        "cc_phone_2"
                                                                      ] !=
                                                                      "" ? (
                                                                      item[
                                                                        "cc_phone_2"
                                                                      ]
                                                                    ) : (
                                                                      "-"
                                                                    )
                                                                  ) : c_k ===
                                                                    "cc_update" ? (
                                                                    <FormButton
                                                                      buttonType="button"
                                                                      buttonValue=""
                                                                      buttonClass="btn text-secondary input-text-size rounded rounded-1"
                                                                      hasIcon={
                                                                        true
                                                                      }
                                                                      iconClass="fas fa-pen "
                                                                      buttonOnClick={() =>
                                                                        handleUpdateContact(
                                                                          cb_i,
                                                                          i_c
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : c_k ===
                                                                    "cc_delete" ? (
                                                                    <FormButton
                                                                      buttonType="button"
                                                                      buttonValue=""
                                                                      buttonClass="btn text-secondary input-text-size rounded rounded-1"
                                                                      hasIcon={
                                                                        true
                                                                      }
                                                                      iconClass="fas fa-trash "
                                                                      buttonOnClick={() =>
                                                                        handleDeleteContact(
                                                                          cb_i,
                                                                          i_c
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : item[
                                                                      c_k
                                                                    ] ? (
                                                                    item[c_k]
                                                                  ) : (
                                                                    "-"
                                                                  )}
                                                                </td>
                                                              )
                                                            )}
                                                          </tr>
                                                        );
                                                      }
                                                    ) ?? null}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )) ?? null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item border-0">
                            <div
                              id="contactTwo"
                              className={
                                "accordion-collapse collapse" +
                                (isContactFormShow == "UpdateContact" ||
                                isContactFormShow == "AddNewContact"
                                  ? "show "
                                  : "")
                              }
                            >
                              <div className="d-flex">
                                <div className="col">
                                  <CheckBox
                                    id={"cc_is_devis_commande"}
                                    className="form-check-input cursor-pointer me-3"
                                    value={cc_is_devis_commande}
                                    onChange={setCc_is_devis_commande}
                                    label={"Contact devis/commande"}
                                  />
                                </div>
                                <div className="col">
                                  <CheckBox
                                    id={"cc_is_facturation_adress"}
                                    className="form-check-input cursor-pointer me-3"
                                    value={cc_is_facturation_adress}
                                    label={"Contact facturation"}
                                    onChange={setCc_is_facturation_adress}
                                  />
                                </div>
                              </div>

                              <FormInput
                                type={"text"}
                                blockClass={"mb-3 me-2 col"}
                                id={"cc_name"}
                                label={"Nom du contact"}
                                placeholder={"Nom du contact"}
                                value={cc_name}
                                isRequired={true}
                                isDisabled={false}
                                errorMessage={error_message["cc_name"]}
                                onChange={(e) => {
                                  setCc_name(e);
                                }}
                              />
                              <FormInput
                                type={"text"}
                                blockClass={"mb-3 me-2 col"}
                                id={"cc_post"}
                                label={"Poste"}
                                placeholder={"Poste"}
                                value={cc_post}
                                isRequired={false}
                                isDisabled={false}
                                errorMessage={""}
                                onChange={setCc_post}
                              />
                              <FormInput
                                type={"text"}
                                blockClass={"mb-3 me-2 col"}
                                id={"cc_email"}
                                label={"Email"}
                                placeholder={"Email"}
                                value={cc_email}
                                isRequired={false}
                                isDisabled={false}
                                errorMessage={error_message["cc_email"]}
                                onChange={onChangeEmail}
                              />
                              <div className="d-flex flex-row">
                                <SelectBoxSearch
                                  type="select"
                                  blockClass="mb-3 me-2 col"
                                  id="cc_country"
                                  labelClass=""
                                  label="Pays"
                                  selectClass="input-label-size "
                                  isRequired={cc_is_facturation_adress}
                                  defaultValue={cc_country}
                                  errorMessage={error_message["cc_country"]}
                                  selectOptions={data_country}
                                  onChange={(e) =>
                                    search_city(
                                      e,
                                      setCc_country,
                                      e,
                                      cc_zipcode,
                                      "contact"
                                    )
                                  }
                                  cle="country_id"
                                  value="country_name"
                                  placeholder="Pays"
                                />
                                <FormInput
                                  type={"text"}
                                  blockClass={"mb-3 me-2 col"}
                                  id={"cc_zipcode"}
                                  label={"Code Postal"}
                                  placeholder={"code Postal"}
                                  value={cc_zipcode}
                                  isRequired={cc_is_facturation_adress}
                                  isDisabled={false}
                                  errorMessage={error_message["cc_zipcode"]}
                                  onChange={(e) =>
                                    search_city(
                                      e,
                                      setCc_zipcode,
                                      cc_country,
                                      e,
                                      "contact"
                                    )
                                  }
                                />
                                <FormInput
                                  type={"text"}
                                  blockClass={"mb-3 me-2 col"}
                                  id={"cc_city"}
                                  label={"Ville"}
                                  placeholder={"Ville"}
                                  value={cc_city}
                                  isRequired={cc_is_facturation_adress}
                                  isDisabled={false}
                                  errorMessage={error_message["cc_city"]}
                                  onChange={setCc_city}
                                />
                              </div>
                              <FormInput
                                type={"text"}
                                blockClass={"mb-3 me-2 col"}
                                id={"cc_adress_1"}
                                label={"Adresse 1"}
                                placeholder={"Adresse 1"}
                                value={cc_adress_1}
                                isRequired={cc_is_facturation_adress}
                                isDisabled={false}
                                errorMessage={error_message["cc_adress_1"]}
                                onChange={setCc_adress_1}
                              />
                              <FormInput
                                type={"text"}
                                blockClass={"mb-3 me-2 col"}
                                id={"cc_adress_2"}
                                label={"Adresse 2"}
                                placeholder={"Adresse 2"}
                                value={cc_adress_2}
                                isRequired={false}
                                isDisabled={false}
                                errorMessage={""}
                                onChange={setCc_adress_2}
                              />
                              <FormInput
                                type={"text"}
                                blockClass={"mb-3 me-2 col"}
                                id={"cc_adress_3"}
                                label={"Adresse 3"}
                                placeholder={"Adresse 3"}
                                value={cc_adress_3}
                                isRequired={false}
                                isDisabled={false}
                                errorMessage={""}
                                onChange={setCc_adress_3}
                              />
                              <div className="d-flex flex-row">
                                <FormInput
                                  type={"text"}
                                  blockClass={"mb-3 me-2 col"}
                                  id={"cc_phone_1"}
                                  label={"Téléphone 1"}
                                  placeholder={"Téléphone 1"}
                                  value={cc_phone_1}
                                  isRequired={false}
                                  isDisabled={false}
                                  errorMessage={""}
                                  onChange={setCc_phone_1}
                                />
                                <FormInput
                                  type={"text"}
                                  blockClass={"mb-3 me-2 col"}
                                  id={"cc_phone_2"}
                                  label={"Téléphone 2"}
                                  placeholder={"Téléphone 2"}
                                  value={cc_phone_2}
                                  isRequired={false}
                                  isDisabled={false}
                                  errorMessage={""}
                                  onChange={setCc_phone_2}
                                />
                                <FormInput
                                  type={"text"}
                                  blockClass={"mb-3 me-2 col"}
                                  id={"cc_fax"}
                                  label={"Fax"}
                                  placeholder={"Fax"}
                                  value={cc_fax}
                                  isRequired={false}
                                  isDisabled={false}
                                  errorMessage={""}
                                  onChange={setCc_fax}
                                />
                              </div>
                              <div className="d-flex justify-content-end pe-3">
                                <FormButton
                                  buttonType="button"
                                  buttonValue="Annuler"
                                  buttonClass="btn btn-light text-secondary input-label-size me-2 rounded rounded-1"
                                  hasIcon={true}
                                  iconClass="far fa-times-circle me-2 "
                                  buttonOnClick={() =>
                                    handleContactForm("CancelAddNewContact")
                                  }
                                />
                                {isContactFormShow === "UpdateContact" ? (
                                  <FormButton
                                    buttonType="button"
                                    buttonValue="Modifier le contact"
                                    buttonClass="btn btn-primary input-label-size me-2 rounded rounded-1"
                                    hasIcon={true}
                                    iconClass="fas fa-check me-2 "
                                    buttonOnClick={submitContact}
                                  />
                                ) : (
                                  <FormButton
                                    buttonType="button"
                                    buttonValue="Ajouter le contact"
                                    buttonClass="btn btn-primary input-label-size me-2 rounded rounded-1"
                                    hasIcon={true}
                                    iconClass="fas fa-check me-2 "
                                    buttonOnClick={submitContact}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          {/* =============Fin contact form */}
                        </div>
                      </div>
                    </FormSection>
                  </div>
                </div>
              </div>
            </div>
            {/* ================ Modal footer : where submit buttons and other buttons are =============== */}
            <div
              className={
                "d-flex justify-content-end mt-3 mb-5 modal-footer p-2"
              }
              style={{ minHeight: "8%" }}
            >
              <div className="d-flex justify-content-start col">
                <FormButton
                  buttonType="button"
                  buttonValue="Annuler"
                  buttonClass="btn btn-light text-secondary input-label-size me-2"
                  hasIcon={true}
                  iconClass="far fa-times-circle me-2"
                  buttonOnClick={closeModal}
                />
              </div>
              <div className="d-flex justify-content-end col">
                {activeItem == 0 && (
                  <>
                    <FormButton
                      buttonType="button"
                      buttonValue="Suivant"
                      buttonClass="btn btn-primary input-label-size me-2"
                      hasIcon={true}
                      iconPlace="right"
                      iconClass="fas fa-angle-right ms-2"
                      buttonOnClick={nextCarouselItem}
                    />
                  </>
                )}
                {activeItem > 0 && (
                  <>
                    <FormButton
                      buttonType="button"
                      buttonValue="Précédent"
                      buttonClass="btn btn-outline-primary text-secondary input-label-size me-2"
                      hasIcon={true}
                      iconClass="fas fa-angle-left me-2"
                      buttonOnClick={previousCarouselItem}
                    />

                    {whyShown > 1 ? (
                      <FormButton
                        buttonType="submit"
                        buttonValue="Modifier le client"
                        buttonClass="btn green-button-bsl input-label-size me-2 rounded rounded-1"
                        hasIcon={true}
                        iconClass="fas fa-check me-2 "
                      />
                    ) : (
                      <FormButton
                        buttonType="submit"
                        buttonValue="Ajouter un client"
                        buttonClass="btn green-button-bsl input-label-size me-2 rounded rounded-1"
                        hasIcon={true}
                        iconClass="fas fa-check me-2 "
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              ref={modalBodyAddClient}
              className="modal-body p-0 ps-5 pe-5"
              style={whyShown < 2 ? { minHeight: "73%" } : { minHeight: "80%" }}
            >
              <div
                id="formUpdatableCarousel"
                className="carousel slide carousel-fade"
              >
                <div className="carousel-inner">
                  <div
                    className={`carousel-item ${
                      carouselItems[0].isActive == true ? "active" : ""
                    }`}
                  >
                    <DisplayCurrentClientGeneral
                      client_num={currentClient?.id_code}
                      client_bsl_num={
                        client_bsl_num < 6
                          ? currentClient?.bsl_code
                          : client_bsl_num
                      }
                      client_company_name={client_company_name}
                      client_country={currentClient?.client_country_name}
                      client_zipcode={client_zipcode}
                      client_city={client_city}
                      client_adress_1={client_adress_1}
                      client_adress_2={client_adress_2}
                      client_adress_3={client_adress_3}
                      client_tva_num={client_tva_num}
                      client_cost_center={client_cost_center}
                      client_comment={client_comment}
                      client_data_branch={data_branch}
                      client_data_contact={cc_data_contact}
                      handleIsUpdateOn={() => setIsUpdateOn(true)}
                    />
                  </div>
                  <div
                    className={`carousel-item ${
                      carouselItems[1].isActive == true ? "active" : ""
                    }`}
                  >
                    <DisplayCurrentClientDevisCommande
                      client_data_branch={data_branch}
                      handleIsUpdateOn={() => setIsUpdateOn(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </ModalForAddNew>
    </Form>
  );
};

export default AddClient;
