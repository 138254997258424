import React, { useState, useRef, useEffect } from "react";
import FormButton from "../../../Form/FormButton";
import Input from "../../../Form/Input";
import FormCard from "../../../Form/FormCard";
import ListGroup from "../../../Form/ListGroup";
import ListItemContents from "../../../Form/ListItemContents";
import ListItems from "../../../Form/ListItems";
import FormSection from "../../../Form/FormSection";
import SelectBox from "../../../Form/SelectBox";
import SelectBoxSearch from "../../../Form/SelectBoxSearch";
import TextArea from "../../../Form/TextArea";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import Form from "../../../Form/Form";
import ShowDetailDemande from "./ShowDetailDemande";
import { SearchCity } from "../../../../hooks/SearchCity";

const DetailDemande = ({
  _demande = {},
  _isUpdate,
  can_update_request = true,
  idUser = 0,
  _setIsUpdate = () => {},
  handleCloseModal = () => {},
  getData = () => {},
  _data_list = {},
  data_country_list = [],
  getDemande = () => {},
}) => {
  const form_data = {
    type: "form",
    id: "addDevis",
    formClass: "",
  };
  const [demande, set_demande] = useState({});

  const formRef = useRef(null);
  const [client_list, set_client_list] = useState([]);
  const [contact, set_contact] = useState([]);
  const [branch, set_branch] = useState([]);

  const [agent_list, set_agent_list] = useState([]);
  const [nombre_colis, set_nombre_colis] = useState([
    { id: 1, value: " 1" },
    { id: 2, value: " 2" },
    { id: 3, value: " 3" },
    { id: 4, value: " 4" },
    { id: 5, value: " 5" },
    { id: 6, value: " 6" },
    { id: 7, value: " 7" },
    { id: 8, value: " 8" },
    { id: 9, value: " 9" },
    { id: 10, value: " 10" },
  ]);

  useEffect(() => {
    set_client_list(_data_list?.client_list);
    set_agent_list(_data_list?.user_agent);
  }, [_data_list]);

  const [req_client_id, set_req_client_id] = useState("");
  const [req_client_contact_id, set_req_client_contact_id] = useState("");
  const [req_client_email_contact, set_req_client_email_contact] = useState("");
  const [req_client_banch_id, set_req_client_banch_id] = useState("");

  const [req_goods_nb_colis, set_req_goods_nb_colis] = useState("");

  const [agent_responsable_id, set_agent_responsable_id] = useState(0);
  const [agent_responsable, set_agent_responsable] = useState("");

  const [req_title, set_req_title] = useState("");
  const [req_date_request, set_req_date_request] = useState("");
  const [req_time_request, set_req_time_request] = useState("");
  const [req_origin_company, set_req_origin_company] = useState("");
  const [req_origin_country, set_req_origin_country] = useState("");
  const [req_origin_zip_code, set_req_origin_zip_code] = useState("");
  const [req_origin_city, set_req_origin_city] = useState("");
  const [req_origin_adress, set_req_origin_adress] = useState("");
  const [req_dest_company, set_req_dest_company] = useState("");
  const [req_dest_country, set_req_dest_country] = useState("");
  const [req_dest_zip_code, set_req_dest_zip_code] = useState("");
  const [req_dest_city, set_req_dest_city] = useState("");
  const [req_dest_adress, set_req_dest_adress] = useState("");
  const [req_goods_gross_weight, set_req_goods_gross_weight] = useState(0);
  const [req_goods_volumetric_weight, set_req_goods_volumetric_weight] =
    useState(0);
  //nouvelle demande
  const [req_goods_length, set_req_goods_length] = useState(0);
  const [req_goods_width, set_req_goods_width] = useState(0);
  const [req_goods_height, set_req_goods_height] = useState(0);
  const [req_fret_taxable_weight, set_req_fret_taxable_weight] = useState(0);
  const [req_weight_taxable_integrator, set_req_weight_taxable_integrator] =
    useState(0);
  const [req_goods_description, set_req_goods_description] = useState("");
  const [req_goods_ref_dbl, set_req_goods_ref_dbl] = useState("");
  const [req_comment, set_req_comment] = useState("");

  const [req_file_email, set_req_file_email] = useState("");

  const [req_file_image, set_req_file_image] = useState("");

  const [reference_client, set_reference_client] = useState("");

  //Stocker les erreur
  const [error_message, setError_message] = useState({
    req_client_id: "",
    reference_client: "",
    req_date_request: "",
    heureDevis: "",
    req_origin_country: "",
    req_origin_zip_code: "",
    req_origin_city: "",
    req_origin_adress: "",
    req_dest_country: "",
    req_dest_zip_code: "",
    req_dest_city: "",
    req_dest_adress: "",
    req_goods_nb_colis: "",
    req_goods_gross_weight: "",
    req_goods_volumetric_weight: "",
    req_client_contact_id: "",
    req_client_banch_id: "",
  });

  //reference to search city api controller
  const req_origin_city_controller = useRef();
  const req_dest_city_controller = useRef();

  const handleChangeBranch = async (id) => {
    const { loading, data, errors } = await FetchData(
      "/api/client/contact/branch/" + id,
      {
        method: "GET",
      }
    );
    set_req_client_banch_id(id);
    set_req_client_contact_id(
      _demande?.req_id > 0 ? _demande?.req_client_contact_id : ""
    );
    set_contact(data.data);
  };

  const handleChangeClient = async (id) => {
    const { loading, data, errors } = await FetchData(
      "/api/client/branch/client/" + id,
      {
        method: "GET",
      }
    );
    set_req_client_id(id);
    set_req_client_banch_id("");
    set_req_client_contact_id("");
    set_contact([]);
    set_branch(data.data);
  };

  const required_input =
    "req_client_banch_id req_client_contact_id req_date_request req_time_request req_origin_country req_origin_zip_code req_origin_city " +
    "req_dest_country req_dest_zip_code req_dest_city  req_goods_gross_weight req_goods_volumetric_weight";

  const required_select = ["req_client_id", "req_goods_nb_colis"];

  useEffect(() => {
    set_demande(_demande);
    set_req_client_email_contact("");
    if (_demande && JSON.stringify(_demande) !== "{}") {
      // set_req_client_id(_demande?.req_client_id);
      handleChangeClient(_demande?.req_client_id)
        .then((responseChangeClient) =>
          handleChangeBranch(_demande?.req_client_banch_id)
        )
        .then((responseChangeBranch) => {
          set_req_title(_demande?.numero);

          set_req_date_request(
            _demande?.req_date_request?.split("/").reverse().join("-")
          );
          set_req_time_request(_demande?.req_time_request);
          set_req_origin_company(_demande?.req_origin_company);
          set_req_origin_country(_demande?.req_origin_country);
          set_req_origin_zip_code(_demande?.req_origin_zip_code);
          set_req_origin_city(_demande?.req_origin_city);
          set_req_origin_adress(_demande?.req_origin_adress);
          set_req_dest_company(_demande?.req_dest_company);
          set_req_dest_country(_demande?.req_dest_country);
          set_req_dest_zip_code(_demande?.req_dest_zip_code);
          set_req_dest_city(_demande?.req_dest_city);
          set_req_dest_adress(_demande?.req_dest_adress);
          set_req_goods_nb_colis(_demande?.req_goods_nb_colis);
          set_req_goods_gross_weight(_demande?.req_goods_gross_weight);
          set_req_goods_volumetric_weight(
            _demande?.req_goods_volumetric_weight
          );
          //les nouvelles colonnes
          set_req_goods_height(_demande?.req_goods_height);
          set_req_goods_length(_demande?.req_goods_length);
          set_req_goods_width(_demande?.req_goods_width);
          set_req_fret_taxable_weight(_demande?.req_fret_taxable_weight);
          set_req_weight_taxable_integrator(
            _demande?.req_weight_taxable_integrator
          );
          set_req_goods_description(_demande?.req_goods_description);
          set_req_goods_ref_dbl(_demande?.req_goods_ref_dbl);
          set_req_comment(_demande?.req_comment);
          set_reference_client(_demande?.req_client_ref);

          let date_url = new Date(_demande.req_created_at);
          date_url =
            date_url.getFullYear() +
            "-" +
            (date_url.getMonth() + 1).toString().padStart(2, "0");

          const url_email = `/api/email/${date_url}/`;
          const url_doc = `/api/document/${date_url}/`;

          set_req_file_email(
            _demande?.req_file_email?.map((d) => ({
              filename: d,
              url: url_email + d,
            }))
          );

          set_req_file_image(
            _demande?.req_file_image.map((d) => ({
              filename: d,
              url: url_doc + d,
            }))
          );
        })
        .catch((error) => console.error("Il y a une erreur : ", error));
    }
  }, [_demande]);

  function resetError() {
    handleCloseModal();
    setError_message({
      req_client_id: "",
      reference_client: "",
      req_date_request: "",
      heureDevis: "",
      req_origin_country: "",
      req_origin_zip_code: "",
      req_origin_city: "",
      req_origin_adress: "",
      req_dest_country: "",
      req_dest_zip_code: "",
      req_dest_city: "",
      req_dest_adress: "",
      req_goods_nb_colis: "",
      req_goods_gross_weight: "",
      req_goods_volumetric_weight: "",
      req_client_contact_id: "",
      req_client_banch_id: "",
    });
    // set_req_file_email([]);
    // set_req_file_image([]);

    set_req_client_id("");
    set_req_date_request("");
    set_req_origin_country("");
    set_req_origin_zip_code("");
    set_req_origin_city("");
    set_req_origin_adress("");
    set_req_dest_country("");
    set_req_dest_zip_code("");
    set_req_dest_city("");
    set_req_dest_adress("");
    set_req_title("");
    set_req_origin_company("");
    set_req_dest_company("");
    //nouvelle colonnes
    set_req_goods_height(0);
    set_req_goods_length(0);
    set_req_goods_width(0);
    set_req_fret_taxable_weight(0);
    set_req_weight_taxable_integrator(0);
    set_req_goods_description("");
    set_req_goods_ref_dbl("");
    set_req_comment("");
    set_req_goods_gross_weight(0);

    set_req_goods_volumetric_weight(0);
    set_req_client_contact_id("");
    set_reference_client("");
    set_req_time_request("");
    set_req_client_id(0);
    set_req_goods_nb_colis(0);
    set_req_client_banch_id("");

    set_demande({});
    set_agent_responsable_id(0);
    set_agent_responsable("");
    _setIsUpdate(false);

    set_contact([]);
    set_branch([]);
  }

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }

    return isEmpty;
  }

  const submitAddDevis = async (e) => {
    e.preventDefault();

    const inputs = new FormData(formRef.current);
    let errors = error_message;
    let can_next = true;

    for (const key of inputs.keys()) {
      // client_bsl_num;
      if (inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        can_next = false;
      } else {
        errors[key] = "";
        errors = { ...errors, key: "" };
      }
    }
    if (parseFloat(req_goods_gross_weight) <= 0) {
      errors = { ...errors, req_goods_gross_weight: "Valeur 0 non-acceptée" };
      can_next = false;
    } else if (!req_goods_gross_weight) {
      errors = { ...errors, req_goods_gross_weight: "Champ non-vide" };
      can_next = false;
    } else {
      errors = { ...errors, req_goods_gross_weight: "" };
    }
    required_select.forEach((key) => {
      if (!inputs.get(key) && inputs.get(key) === null) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        can_next = false;
      } else {
        errors[key] = "";
        errors = { ...errors, key: "" };
      }
    });
    setError_message(errors);

    if (can_next) {
      const newrequest = {
        req_title,
        req_client_id,
        req_client_ref: reference_client,
        req_date_request,
        req_time_request,
        req_client_banch_id,
        req_client_contact_id,
        req_origin_company,
        req_origin_country,
        req_origin_zip_code,
        req_origin_city,
        req_origin_adress,
        req_dest_company,
        req_dest_country,
        req_dest_zip_code,
        req_dest_city,
        req_dest_adress,
        req_goods_nb_colis,
        req_goods_gross_weight,
        // req_goods_volumetric_weight,
        req_goods_height,
        req_goods_length,
        req_goods_width,
        //parser
        req_fret_taxable_weight,
        req_weight_taxable_integrator,
        req_goods_ref_dbl,
        req_goods_description,
        req_file_email: req_file_email.map((d) => d.filename),
        req_file_image: req_file_image.map((d) => d.filename),
        req_comment,
        req_user_id_create: idUser,
        req_user_id_update: idUser,
        req_client_email_contact: req_client_email_contact,
      };
      try {
        let url = "",
          options = {},
          message = {};

        if (demande?.req_id > 0) {
          url = `/api/request/${demande?.req_id}`;
          options = {
            method: "PUT",
            body: JSON.stringify(newrequest),
          };
          message = {
            title: "Succès",
            text: "Modification de la demande effectuée.",
          };
        }
        const { loading, data, errors } = await FetchData(url, options);
        if (data.status === 200) {
          Swal.fire({
            ...message,
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          resetError();
        } else {
          Swal.fire({
            title: "Erreur!",
            text: data.error,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        getDemande(demande?.req_id);
        getData();
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
      }
    } else {
      can_next = true;
    }
  };

  const handleChangeAgentResponsable = async (id) => {
    const { loading, data, errors } = await FetchData("/api/request/assign", {
      method: "POST",
      body: JSON.stringify({
        rau_agent_id: id,
        rau_user_id_create: idUser,
        rau_user_id_update: idUser,
        rau_req_id: _demande?.req_id,
      }),
    });
    if (data.status === 200) {
      set_agent_responsable_id(id);
      set_agent_responsable(data.data);
      Swal.fire({
        title: "Succès",
        text: "Assignation du responsable réussie.",
        icon: "success",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      getDemande(demande?.req_id);
    } else {
      Swal.fire({
        title: "Erreur!",
        text: data?.error,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    const volume =
      parseFloat(req_goods_length) *
      parseFloat(req_goods_width) *
      parseFloat(req_goods_height);
    set_req_fret_taxable_weight(
      isNaN(volume / 6000) ? 0 : (volume / 6000).toFixed(2)
    );
    set_req_weight_taxable_integrator(
      isNaN(volume / 5000) ? 0 : (volume / 5000).toFixed(2)
    );
  }, [req_goods_length, req_goods_width, req_goods_height]);

  const search_city = (
    value,
    setNewValue = () => {},
    target_country = 0,
    target_zipcode = "",
    city_owner = "req_origin"
  ) => {
    switch (city_owner) {
      case "req_origin":
        req_origin_city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          set_req_origin_city,
          req_origin_city_controller.current,
          data_country_list
        );
        break;
      case "req_dest":
        req_dest_city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          set_req_dest_city,
          req_dest_city_controller.current,
          data_country_list
        );
        break;
    }

    setNewValue(value);
  };

  return (
    <>
      {_demande && !_isUpdate ? (
        <>
          {[3, 4, 5].includes(_demande?.req_id_status) ||
          !can_update_request ? (
            <div className="mt-3"></div>
          ) : (
            <div className=" container-fluid d-flex mt-2 mb-2  ps-4 pt-3 pb-3 justify-content-end ">
              <FormButton
                buttonType="button"
                buttonValue="Modifier"
                buttonClass="btn btn-outline-primary input-label-size me-2"
                hasIcon={true}
                iconClass="fas fa-pen me-2"
                loseModal={false}
                buttonOnClick={() => _setIsUpdate(true)}
              />
            </div>
          )}
          <ShowDetailDemande _demande={_demande} />
        </>
      ) : (
        <>
          <Form
            formData={form_data}
            // submitFunction={submitAddDevis}
            formRef={formRef}
          >
            {/* pour les agents responsable */}
            {_demande?.req_id_status !== 7 && _demande !== null && (
              <FormSection
                formSectionData={{
                  type: "section",
                  title: "Responsable",
                  sectionClass: "border-bottom",
                }}
              >
                <SelectBoxSearch
                  type="select"
                  blockClass="col-6 col-sm-4"
                  id="agent_responsable"
                  labelClass=""
                  label="Agent responsable"
                  selectClass="input-label-size "
                  isRequired={false}
                  selectOptions={agent_list}
                  onChange={handleChangeAgentResponsable}
                  value="username"
                  placeholder={
                    demande?.rau_agent_id !== null
                      ? demande?.rau_username
                      : "Assigné un responsable"
                  }
                />
                {_demande?.rau_agent_id !== null ? (
                  <div className="d-flex flex-row">
                    <Input
                      type="text"
                      blockClass="mb-3 me-2 col"
                      id="agent_poste"
                      labelClass="text-muted"
                      label="Poste"
                      inputClass="custom-input-colors"
                      placeholder="Poste"
                      isRequired={false}
                      isDisabled={true}
                      errorMessage={""}
                      value={_demande?.rau_user_role_name}
                    />
                    <Input
                      type="text"
                      blockClass="mb-3 me-2 col"
                      id="agent_mail"
                      labelClass="text-muted"
                      label="E-mail"
                      inputClass="custom-input-colors"
                      placeholder="E-mail"
                      isRequired={false}
                      isDisabled={true}
                      errorMessage={""}
                      value={_demande?.rau_email}
                    />
                    <Input
                      type="text"
                      blockClass="mb-3 me-2 col"
                      id="agent_lname"
                      labelClass="text-muted"
                      label="Nom"
                      inputClass="custom-input-colors"
                      placeholder="Nom"
                      isRequired={false}
                      isDisabled={true}
                      errorMessage={""}
                      value={_demande?.rau_lname}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </FormSection>
            )}
            <FormSection
              formSectionData={{
                type: "section",
                title: "Général",
                sectionClass: "border-bottom",
              }}
            >
              <Input
                type={"text"}
                blockClass={"mb-3 me-2 col"}
                id={"req_title"}
                labelClass={""}
                label={"Titre"}
                inputClass=""
                placeholder="Titre"
                isRequired={false}
                isDisabled={true}
                errorMessage=""
                value={_demande?.numero}
                //onChange={set_req_title}
              />
              <div className="d-flex flex-row">
                {/* <SelectBox
                  type="select"
                  blockClass="mb-3 me-2 col"
                  id="req_client_id"
                  labelClass=""
                  label="Client"
                  selectClass="input-label-size "
                  isRequired={true}
                  selectOptions={client_list}
                  cle="client_id"
                  value="client_company_name"
                  placeholder="Client"
                  value_selected={req_client_id}
                  onChange={handleChangeClient}
                  errorMessage={error_message["req_client_id"]}
                /> */}
                <SelectBoxSearch
                  type="select"
                  blockClass="mb-3 me-2 col"
                  id="req_client_id"
                  labelClass=""
                  label="Client"
                  selectClass="input-label-size "
                  isRequired={true}
                  defaultValue={req_client_id}
                  errorMessage={error_message["req_client_id"]}
                  selectOptions={client_list}
                  onChange={handleChangeClient}
                  cle="client_id"
                  value="client_company_name"
                  placeholder="Client"
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="reference_client"
                  labelClass=""
                  label="Référence client"
                  inputClass="custom-input-colors"
                  placeholder="Référence client"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                  value={reference_client}
                  onChange={set_reference_client}
                />
                <SelectBoxSearch
                  type="select"
                  blockClass="mb-3 me-2 col"
                  id="req_client_banch_id"
                  labelClass=""
                  label="Branche"
                  selectClass="input-label-size "
                  isRequired={true}
                  defaultValue={req_client_banch_id}
                  errorMessage={error_message["req_client_banch_id"]}
                  selectOptions={branch}
                  onChange={handleChangeBranch}
                  cle="cb_id"
                  value="cb_name"
                  placeholder="Branche"
                />
              </div>
              <div className="d-flex flex-row">
                <Input
                  type="date"
                  blockClass="mb-3 me-2 col"
                  id="req_date_request"
                  labelClass=""
                  label="Date de demande"
                  inputClass="custom-input-colors"
                  placeholder="Date de demande"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_date_request"]}
                  value={req_date_request}
                  onChange={set_req_date_request}
                />
                <Input
                  type="time"
                  blockClass="mb-3 me-2 col"
                  id="req_time_request"
                  labelClass=""
                  label="Heure"
                  inputClass="custom-input-colors"
                  placeholder="Heure"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_time_request"]}
                  value={req_time_request}
                  onChange={set_req_time_request}
                />
              </div>
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Contact",
                sectionClass: "border-bottom",
              }}
            >
              <SelectBoxSearch
                type="select"
                blockClass="mb-3 me-2 col-4"
                id="req_client_contact_id"
                labelClass=""
                label="Contact"
                selectClass="input-label-size "
                isRequired={true}
                defaultValue={req_client_contact_id}
                errorMessage={error_message["req_client_contact_id"]}
                selectOptions={contact}
                onChange={set_req_client_contact_id}
                cle="cc_id"
                value="cc_name"
                placeholder={"Choix du contact"}
              />
              {req_client_contact_id > 0 ||
              _demande?.req_client_contact_id > 0 ? (
                <div className="d-flex flex-row">
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="reference_client"
                    labelClass="text-muted"
                    label="Poste"
                    inputClass="custom-input-colors"
                    placeholder="Poste"
                    isRequired={false}
                    isDisabled={true}
                    errorMessage={""}
                    value={
                      contact?.filter(
                        (c) =>
                          c?.cc_id ===
                          parseInt(
                            req_client_contact_id ||
                              _demande?.req_client_contact_id
                          )
                      )[0]?.cc_post
                    }
                    // onChange={set_reference_client}
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="reference_client"
                    labelClass=""
                    label="E-mail"
                    inputClass="custom-input-colors"
                    placeholder="E-mail"
                    isRequired={false}
                    isDisabled={false}
                    errorMessage={""}
                    value={
                      req_client_email_contact?.length > 0
                        ? req_client_email_contact
                        : contact?.filter(
                            (c) =>
                              c?.cc_id ===
                              parseInt(
                                req_client_contact_id ||
                                  _demande?.req_client_contact_id
                              )
                          )[0]?.cc_email
                    }
                    onChange={set_req_client_email_contact}
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="reference_client"
                    labelClass="text-muted"
                    label="Téléphone"
                    inputClass="custom-input-colors"
                    placeholder="Téléphone"
                    isRequired={false}
                    isDisabled={true}
                    errorMessage={""}
                    value={
                      contact?.filter(
                        (c) =>
                          c?.cc_id ===
                          parseInt(
                            req_client_contact_id ||
                              _demande?.req_client_contact_id
                          )
                      )[0]?.cc_phone_1
                    }
                    // onChange={set_reference_client}
                  />
                </div>
              ) : (
                <></>
              )}
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Origine",
                sectionClass: "border-bottom",
              }}
            >
              <Input
                type={"text"}
                blockClass={"mb-3 me-2 col"}
                id={"req_origin_company"}
                labelClass={""}
                label={"Nom de la société"}
                inputClass=""
                placeholder="Nom de la société"
                isRequired={false}
                isDisabled={false}
                errorMessage=""
                value={req_origin_company}
                onChange={set_req_origin_company}
              />
              <div className="d-flex flex-row">
                <SelectBoxSearch
                  type="select"
                  blockClass="mb-3 me-2 col"
                  id="req_origin_country"
                  labelClass=""
                  label="Pays"
                  selectClass="input-label-size "
                  isRequired={true}
                  defaultValue={_demande?.req_origin_country}
                  errorMessage={error_message["req_origin_country"]}
                  selectOptions={data_country_list}
                  onChange={(e) =>
                    search_city(
                      e,
                      set_req_origin_country,
                      e,
                      req_origin_zip_code,
                      "req_origin"
                    )
                  }
                  cle="country_id"
                  value="country_name"
                  placeholder="Pays"
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_origin_zip_code"
                  labelClass=""
                  label="Code postal"
                  inputClass="custom-input-colors"
                  placeholder="Code postal"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_origin_zip_code"]}
                  value={req_origin_zip_code}
                  onChange={(e) =>
                    search_city(
                      e,
                      set_req_origin_zip_code,
                      req_origin_country,
                      e,
                      "req_origin"
                    )
                  }
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_origin_city"
                  labelClass=""
                  label="Ville"
                  inputClass="custom-input-colors"
                  placeholder="Ville"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_origin_city"]}
                  value={req_origin_city}
                  onChange={set_req_origin_city}
                />
              </div>
              <div className="d-flex flex-row">
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_origin_adress"
                  labelClass=""
                  label="Adresse"
                  inputClass="custom-input-colors"
                  placeholder="Adresse"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                  value={req_origin_adress}
                  onChange={set_req_origin_adress}
                />
              </div>
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Destination",
                sectionClass: "border-bottom",
              }}
            >
              <Input
                type={"text"}
                blockClass={"mb-3 me-2 col"}
                id={"req_dest_company"}
                labelClass={""}
                label={"Nom de la société"}
                inputClass=""
                placeholder="Nom de la société"
                isRequired={false}
                isDisabled={false}
                errorMessage=""
                value={req_dest_company}
                onChange={set_req_dest_company}
              />
              <div className="d-flex flex-row">
                <SelectBoxSearch
                  type="select"
                  blockClass="mb-3 me-2 col"
                  id="req_dest_country"
                  labelClass=""
                  label="Pays"
                  selectClass="input-label-size "
                  isRequired={true}
                  defaultValue={_demande?.req_dest_country}
                  errorMessage={error_message["req_dest_country"]}
                  selectOptions={data_country_list}
                  onChange={(e) =>
                    search_city(
                      e,
                      set_req_dest_country,
                      e,
                      req_dest_zip_code,
                      "req_dest"
                    )
                  }
                  cle="country_id"
                  value="country_name"
                  placeholder="Pays"
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_dest_zip_code"
                  labelClass=""
                  label="Code postal"
                  inputClass="custom-input-colors"
                  placeholder="Code postal"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_dest_zip_code"]}
                  value={req_dest_zip_code}
                  onChange={(e) =>
                    search_city(
                      e,
                      set_req_dest_zip_code,
                      req_dest_country,
                      e,
                      "req_dest"
                    )
                  }
                />

                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_dest_city"
                  labelClass=""
                  label="Ville"
                  inputClass="custom-input-colors"
                  placeholder="Ville"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_dest_city"]}
                  value={req_dest_city}
                  onChange={set_req_dest_city}
                />
              </div>
              <div className="d-flex flex-row">
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_dest_adress"
                  labelClass=""
                  label="Adresse"
                  inputClass="custom-input-colors"
                  placeholder="Adresse"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                  value={req_dest_adress}
                  onChange={set_req_dest_adress}
                />
              </div>
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Marchandises",
                sectionClass: "border-bottom",
              }}
            >
              <div className="d-flex flex-row">
                <Input
                  type={"number"}
                  blockClass={"mb-3 me-2 col"}
                  id={"req_goods_nb_colis"}
                  labelClass={""}
                  label={"Nombre de colis"}
                  inputClass=""
                  placeholder="Nombre de colis"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_goods_nb_colis"]}
                  value={req_goods_nb_colis}
                  onChange={set_req_goods_nb_colis}
                  suffix=""
                />
                <Input
                  type={"number"}
                  blockClass={"mb-3 me-2 col"}
                  id={"req_goods_gross_weight"}
                  labelClass={""}
                  label={"Poids brute"}
                  inputClass=""
                  placeholder="Poids brute"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_goods_gross_weight"]}
                  value={req_goods_gross_weight}
                  onChange={set_req_goods_gross_weight}
                  suffix="Kg"
                />
                {/* <Input
                  type={"number"}
                  blockClass={"mb-3 me-2 col"}
                  id={"req_goods_volumetric_weight"}
                  labelClass={""}
                  label={"Poids volumétrique"}
                  inputClass=""
                  placeholder="Poids volumétrique"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["req_goods_volumetric_weight"]}
                  value={req_goods_volumetric_weight}
                  onChange={set_req_goods_volumetric_weight}
                  suffix="Kg"
                /> */}
              </div>
              <div className="d-flex flex-row">
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_goods_length"
                  labelClass=""
                  label="Longueur"
                  inputClass="custom-input-colors"
                  placeholder="Longueur"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                  value={req_goods_length}
                  onChange={set_req_goods_length}
                  suffix="cm"
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_goods_width"
                  labelClass=""
                  label="Largeur"
                  inputClass="custom-input-colors"
                  placeholder="Largeur"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                  value={req_goods_width}
                  onChange={set_req_goods_width}
                  suffix="cm"
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_goods_height"
                  labelClass=""
                  label="Hauteur"
                  inputClass="custom-input-colors"
                  placeholder="Hauteur"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                  value={req_goods_height}
                  onChange={set_req_goods_height}
                  suffix="cm"
                />
              </div>
              <div className="d-flex flex-row">
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_goods_length"
                  labelClass=""
                  label="Poids Taxable Fret"
                  inputClass="custom-input-colors"
                  placeholder="Poids taxable Fret"
                  isRequired={false}
                  isDisabled={true}
                  errorMessage={""}
                  value={req_fret_taxable_weight}
                  onChange={(e) => set_req_fret_taxable_weight(parseFloat(e))}
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="req_goods_width"
                  labelClass=""
                  label="Poids Taxable Intégrateur"
                  inputClass="custom-input-colors"
                  placeholder="Poids taxable Intégrateur"
                  isRequired={false}
                  isDisabled={true}
                  errorMessage={""}
                  value={req_weight_taxable_integrator}
                  onChange={(e) =>
                    set_req_weight_taxable_integrator(parseFloat(e))
                  }
                />
              </div>
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="req_goods_ref_dbl"
                labelClass=""
                label="INCOTERM"
                inputClass="custom-input-colors"
                placeholder="INCOTERM"
                isRequired={false}
                isDisabled={false}
                errorMessage={""}
                value={req_goods_ref_dbl}
                onChange={set_req_goods_ref_dbl}
              />
              <TextArea
                blockClass={"mb-3 me-2 col"}
                id={"req_goods_description"}
                labelClass={""}
                label={"Descriptif"}
                inputClass=""
                placeholder="Commentaire"
                isRequired={false}
                value={req_goods_description}
                onChange={set_req_goods_description}
              />
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Commentaire",
              }}
            >
              <TextArea
                blockClass={"mb-3 me-2 col"}
                id={"req_comment"}
                labelClass={""}
                label={"Commentaire"}
                inputClass=""
                placeholder="Commentaire"
                isRequired={false}
                value={req_comment}
                onChange={set_req_comment}
              />
            </FormSection>
            <div
              className={"d-flex justify-content-end mt-2 mb-- modal-footer"}
            >
              <FormButton
                buttonType="button"
                buttonValue="Annuler"
                buttonClass="btn text-secondary input-label-size me-2"
                hasIcon={true}
                iconClass="far fa-times-circle me-2 bg-light"
                closeModal={true}
                buttonOnClick={resetError}
              />
              <FormButton
                buttonValue="Valider modification Demande"
                buttonClass="btn green-button-bsl input-label-size me-2"
                hasIcon={true}
                iconClass="fas fa-check me-2"
                closeModal={false}
                buttonOnClick={(e) => submitAddDevis(e)}
              />
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default DetailDemande;
