import React, { useEffect, useRef, useState } from "react";
import FormSection from "../../../Form/FormSection";
import FormButton from "../../../Form/FormButton";
import SelectBox from "../../../Form/SelectBox";
import Input from "../../../Form/Input";
import Form from "../../../Form/Form";
import RadioButton from "../../../Form/RadioButton";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import ListItems from "../../../Form/ListItems";
import FormCard from "../../../Form/FormCard";
import ListItemContents from "../../../Form/ListItemContents";
import ListGroup from "../../../Form/ListGroup";
import DetailResponseDemande from "./DetailResponseDemande";
import TemplateEmail from "./TemplateEmail";
import TextArea from "../../../Form/TextArea";

const RepondreDemande = ({
  answer = {},
  _setIsOnResponsDemand = () => {},
  _demande,
  _idUser,
  handleCloseModal,
  getData,
  _sous_traitans,
  isShowUpdate = true,
  can_update_answer = true,
  _data_list,
}) => {
  const formRef = useRef(null);
  const [req_id, set_req_id] = useState("");
  const [isUpdate, set_isUpdate] = useState();
  const [show_mail, set_show_mail] = useState(false);

  useEffect(() => {
    set_req_id(_demande?.req_id);
    set_responsable(_demande?.rau_username);
  }, [_demande]);

  useEffect(() => {
    set_isUpdate(isShowUpdate);
  }, []);

  const getDateTime = async () => {
    const { loading, data, errors } = await FetchData(
      "/api/request/date-creation",
      {
        method: "GET",
      }
    );
    if (!errors) {
      if (!answer?.req_as_date_answer) {
        set_date_reponse(data.data.date);
        set_heure_reponse(data.data.time);
      }
    }
  };
  useEffect(() => {
    getDateTime();
  }, [isShowUpdate, answer]);

  //champ input required
  const [date_reponse, set_date_reponse] = useState("");
  const [heure_reponse, set_heure_reponse] = useState("");
  const [prix_vente, set_prix_vente] = useState(0);
  const [prix_achat, set_prix_achat] = useState(0);
  const [nom_frais_divers, set_nom_frais_divers] = useState("");
  const [montant_frais_divers, set_montant_frais_divers] = useState(0);
  const [cout_1, set_cout_1] = useState("");
  const [reference_1, set_reference_1] = useState("");
  const [cout_2, set_cout_2] = useState("");
  const [reference_2, set_reference_2] = useState("");
  //champ select
  const [nb_sous_traitants, set_nb_sous_traitants] = useState("");

  const [subcontractor, setSubcontractor] = useState([]);
  const [errorSub, setErrorSub] = useState([]);

  //champ input not required
  const [responsable, set_responsable] = useState("");
  const [marge_brute, set_marge_brute] = useState(0);
  const [taux_marge, set_taux_marge] = useState(0);
  const [taux_marque, set_taux_marque] = useState("");
  //radio bouton
  const [tva, set_tva] = useState(0);
  const [partiel, set_partiel] = useState(20);

  const [current_answer, set_current_answer] = useState({});
  const [mail_data, set_mail_data] = useState({});

  const required_input =
    "date_reponse heure_reponse cout_1 reference_1 cout_2 reference_2 prix_vente prix_achat partiel";

  const required_select = ["nb_sous_traitants"];

  const sous_traitant_nombres = [
    { id: 1, label: 1 },
    { id: 2, label: 2 },
    { id: 3, label: 3 },
    { id: 4, label: 4 },
    { id: 5, label: 5 },
  ];

  useEffect(() => {
    let add = {
      req_ast_cost: 0,
      req_ast_id: 0,
      req_ast_ref: "",
      req_ast_subcontractor_id: 0,
      req_ast_user_id_create: _idUser,
      req_ast_user_id_update: _idUser,
      req_ast_req_as_id: answer?.req_as_id,
    };
    var all_sub = [];
    if (nb_sous_traitants > subcontractor.length) {
      // add at list
      for (let i = 0; i < nb_sous_traitants - subcontractor.length; i++) {
        all_sub.push(add);
      }
      setSubcontractor((e) => [...e, ...all_sub]);
      setErrorSub((e) => [
        ...e,
        ...all_sub.map((d) => ({
          error_cost: "",
          error_ref: "",
          error_sub: "",
        })),
      ]);
    } else if (nb_sous_traitants < subcontractor.length) {
      // remove at list
      setSubcontractor((e) => e.filter((d, i) => i < nb_sous_traitants));
      setErrorSub((e) => e.filter((d, i) => i < nb_sous_traitants));
    }
  }, [nb_sous_traitants]);

  useEffect(() => {
    set_responsable(_demande?.rau_username);
    //champ input required
    if (!["[]", "{}"].includes(JSON.stringify(answer))) {
      const length_sub = answer?.sous_traitant?.length
        ? answer?.sous_traitant?.length
        : 0;
      if (length_sub) {
        setSubcontractor(
          answer?.sous_traitant?.map((d) => ({
            ...d,
            req_ast_ref: d.req_ast_ref !== null ? d.req_ast_ref : "",
            req_ast_user_id_update: _idUser,
          }))
        );
        setErrorSub(
          answer?.sous_traitant?.map((d) => ({
            error_cost: "",
            error_ref: "",
            error_sub: "",
          }))
        );
      }
      set_prix_vente(answer?.req_as_sell_price);
      set_prix_achat(answer?.req_as_buying_price);
      set_nom_frais_divers(answer?.req_as_fees_miscellaneous_name);
      set_montant_frais_divers(answer?.req_as_fees_miscellaneous_cost);
      //champ select
      set_nb_sous_traitants(length_sub);
      //champ input not required
      set_marge_brute(answer?.req_as_gross_margin);
      set_taux_marge(answer?.req_as_margin_rate);
      set_taux_marque(answer?.req_as_rate_mark);
      //radio bouton
      set_tva(answer?.req_as_tva);
      set_partiel(answer?.req_as_partial);
      set_current_answer(answer);
    } else {
      setError_message({
        date_reponse: "",
        heure_reponse: "",
        cout_1: "",
        reference_1: "",
        cout_2: "",
        reference_2: "",
        prix_vente: "",
        prix_achat: "",
        nb_sous_traitants: "",
        partiel: "",
      });
      //champ input required
      set_date_reponse("");
      set_heure_reponse("");
      set_cout_1("");
      set_reference_1("");
      set_cout_2("");
      set_reference_2("");
      set_prix_vente(0);
      set_prix_achat(0);
      set_nom_frais_divers("");
      set_montant_frais_divers(0);
      //champ select
      set_nb_sous_traitants(0);
      //champ input not required
      set_marge_brute(0);
      set_taux_marge(0);
      set_taux_marque(0);
      //radio bouton
      set_tva(parseInt("0"));
      set_partiel(20);
      set_current_answer({});
    }
  }, [answer]);

  const handleChangeSubstractor = (data, index) => {
    setErrorSub((e) =>
      e.map((d, i) => ({
        ...d,
        error_sub:
          i == index
            ? parseInt(data) > 0
              ? ""
              : "Champ non-vide"
            : d.error_sub,
      }))
    );
    setSubcontractor((e) =>
      e.map((d, i) => ({
        ...d,
        req_ast_subcontractor_id:
          i == index ? parseInt(data) : d?.req_ast_subcontractor_id,
      }))
    );
  };

  const handleChangeSubCost = (data, index) => {
    setSubcontractor((e) =>
      e.map((d, i) => ({
        ...d,
        req_ast_cost:
          i == index
            ? isNaN(parseFloat(data))
              ? ""
              : parseFloat(data)
            : d?.req_ast_cost,
      }))
    );
    setErrorSub((e) =>
      e.map((d, i) => ({
        ...d,
        error_cost:
          i == index
            ? isNaN(parseFloat(data))
              ? "Champ non-vide"
              : parseFloat(data) > 0
              ? ""
              : "Nombre incorrect"
            : d.error_cost,
      }))
    );
  };

  const handleChangeSubRef = (data, index) => {
    setSubcontractor((e) =>
      e.map((d, i) => ({
        ...d,
        req_ast_ref: i == index ? data : d?.req_ast_ref,
      }))
    );
  };

  const [error_message, setError_message] = useState({
    date_reponse: "",
    partiel: "",
    heure_reponse: "",
    cout_1: "",
    reference_1: "",
    cout_2: "",
    reference_2: "",
    prix_vente: "",
    prix_achat: "",
    nb_sous_traitants: "",
  });

  const [error_list, set_error_list] = useState([]);

  const resetError = () => {
    setError_message({
      date_reponse: "",
      heure_reponse: "",
      cout_1: "",
      reference_1: "",
      cout_2: "",
      reference_2: "",
      prix_vente: "",
      prix_achat: "",
      nb_sous_traitants: "",
      partiel: "",
    });
    set_error_list([]);
    handleCloseModal();
    //champ input required
    set_date_reponse("");
    set_heure_reponse("");
    set_cout_1("");
    set_reference_1("");
    set_cout_2("");
    set_reference_2("");
    set_prix_vente(0);
    set_prix_achat(0);
    set_nom_frais_divers("");
    set_montant_frais_divers(0);
    //champ select
    set_nb_sous_traitants(0);
    //champ input not required
    set_responsable("");
    set_marge_brute(0);
    set_taux_marge(0);
    set_taux_marque(0);
    //radio bouton
    set_tva(parseInt("1"));
    set_partiel(0);

    _setIsOnResponsDemand(false);
  };

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }
    return isEmpty;
  }

  function handleNoTva(data) {
    let newErrors = error_message;
    set_tva(parseInt(data));
    switch (parseInt(data)) {
      case 0:
        set_partiel(20);
        newErrors = {
          ...newErrors,
          partiel: "",
        };
        break;
      case 1:
        set_partiel(0);
        newErrors = {
          ...newErrors,
          partiel: "",
        };
        break;
      case 2:
        if (partiel === "") {
          newErrors = {
            ...newErrors,
            partiel: "non vide",
          };
        } else if (parseInt(partiel) === 0) {
          newErrors = {
            ...newErrors,
            partiel: "non zero",
          };
        }
        break;
      default:
    }
    setError_message(newErrors);
  }

  function handleTVAInput(data) {
    set_partiel(data);
    let newErrors = error_message;
    if (data === "" && (tva == 0 || tva == 2)) {
      newErrors = {
        ...newErrors,
        partiel: "non vide",
      };
    } else if (parseInt(data) === 0 && (tva == 0 || tva == 2)) {
      newErrors = {
        ...newErrors,
        partiel: "non zero",
      };
    } else {
      newErrors = {
        ...newErrors,
        partiel: "",
      };
    }

    setError_message(newErrors);
  }

  useEffect(() => {
    if (parseFloat(partiel) > 100) {
      set_partiel(100);
    }
  }, [partiel]);

  const choiseSendEmail = (data_) => {
    Swal.fire({
      title: "Envoyer un email",
      text: "Voulez-vous envoyer un email?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, envoyer!",
      confirmButtonColor: "#0e3feb",
      denyButtonColor: "#00d78c",
      reverseButtons: true,
      denyButtonText: `Non, répondre seulement`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        set_mail_data(data_?.data);
        set_show_mail(true);
      } else if (result.isDenied) {
        try {
          let url = "/api/request/without-send-mail";
          let options = {
            method: "POST",
            body: JSON.stringify({
              data_res: {
                req_as_req_id: data_?.data?.request_id,
                req_as_user_id_create: _idUser,
              },
            }),
          };

          const { loading, data, error } = await FetchData(url, options);

          if (data.status === 200) {
            Swal.fire({
              title: "Répondus!",
              text: "Réponse bien validé.",
              icon: "success",
              position: "center",
              showConfirmButton: false,
              timer: 3000,
            });
            handleCloseModal();
            _setIsOnResponsDemand(false);
            getData();
          } else {
            Swal.fire({
              title: "Erreur!",
              text: data.error,
              icon: "error",
              position: "center",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        } catch (error) {
          console.error("Oups une erreur du serveur!!!\n", error);
        }
      }
    });
  };

  const onSubmitResponsDemande = async (e) => {
    e.preventDefault();
    const inputs = new FormData(formRef.current);
    let errors = error_message;
    let newErrorList = error_list;
    let validate = true;

    for (const key of inputs.keys()) {
      if (inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        validate = false;

        const errorElement = document.getElementById(key);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }

    required_select.forEach((field) => {
      if (!inputs.get(field) && inputs.get(field) === null) {
        errors[field] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        validate = false;
      }
    });

    if (
      !isNaN(parseInt(parseInt(inputs.get("prix_vente")))) &&
      parseInt(inputs.get("prix_vente")) === 0
    ) {
      errors["prix_vente"] = "Nombre incorrect";
      validate = false;
    } else {
      errors["prix_vente"] = "";
    }
    let new_error_sub = [];
    subcontractor.forEach((item, i) => {
      new_error_sub.push({
        error_cost: isNaN(parseFloat(item.req_ast_cost))
          ? "Champ non-vide"
          : parseFloat(item.req_ast_cost) > 0
          ? ""
          : "Nombre incorrect",
        error_sub: item.req_ast_subcontractor_id ? "" : "Champ non-vide",
      });
    });
    setErrorSub(new_error_sub);

    let is_valid_error_sub =
      new_error_sub.filter(
        (d, i) => d.error_sub.length > 0 || d.error_cost.length > 0
      ).length == 0;

    setError_message(errors);
    if (validate && is_valid_error_sub) {
      const newDemandResponse = {
        req_as_id: answer?.req_as_id,
        req_as_req_id: req_id,
        req_as_sell_price: parseFloat(prix_vente),
        req_as_buying_price: parseFloat(prix_achat),
        req_as_tva: tva,
        req_as_partial: partiel ? parseFloat(partiel) : 0,
        req_as_gross_margin: parseFloat(marge_brute),
        req_as_margin_rate: parseFloat(taux_marge),
        req_as_rate_mark: parseFloat(taux_marque),
        req_as_fees_miscellaneous_name: nom_frais_divers,
        req_as_fees_miscellaneous_cost: parseFloat(montant_frais_divers),
        req_as_user_id_create: _idUser,
        req_as_user_id_update: _idUser,
        sous_traitant: subcontractor,
      };
      set_current_answer(newDemandResponse);
      try {
        let notif;
        if (
          answer?.length !== 0 ||
          (answer?.sous_traitant &&
            answer?.sous_traitant.length > 0 !== undefined)
        ) {
          if (_demande?.req_id_status === 2) {
            const { loading, data, errors } = await FetchData(
              `/api/request/response/${parseInt(answer?.req_as_id)}`,
              {
                method: "PUT",
                body: JSON.stringify({ ...newDemandResponse, save_only: 1 }),
              }
            );
            if (data?.status === 200) {
              notif = {
                title: "Succès",
                text: "Réponse bien modifiée.",
                icon: "success",
              };
              getData();
              resetError();
            } else {
              const { erreur } = data;
              notif = {
                title: "Erreur!",
                text: data.error,
                icon: "error",
              };
            }
            Swal.fire({
              title: notif.title,
              text: notif.text,
              icon: notif.icon,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            const { loading, data, errors } = await FetchData(
              `/api/request/response/valid-save/${parseInt(answer?.req_as_id)}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  ...newDemandResponse,
                  save_only: 2,
                  client_contact_id: _demande?.req_client_contact_id,
                  userId: _idUser,
                }),
              }
            );
            if (data?.status === 200) {
              choiseSendEmail(data);
            } else {
              const { erreur } = data;
              notif = {
                title: "Erreur!",
                text: data.error,
                icon: "error",
              };
              Swal.fire({
                title: notif.title,
                text: notif.text,
                icon: notif.icon,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
        } else {
          const { loading, data, errors } = await FetchData(
            "/api/request/response",
            {
              method: "POST",
              body: JSON.stringify({
                ...newDemandResponse,
                save_only: 2,
                client_contact_id: _demande?.req_client_contact_id,
                userId: _idUser,
              }),
            }
          );
          if (data?.status === 200) {
            choiseSendEmail(data);
          } else {
            const { erreur } = data;
            notif = {
              title: "Erreur!",
              text: data.error,
              icon: "error",
            };
            Swal.fire({
              title: notif.title,
              text: notif.text,
              icon: notif.icon,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
      }
    } else {
      validate = true;
    }
  };

  const onSaveDemande = async (e) => {
    e.preventDefault();

    const newDemandResponse = {
      save_only: 1,
      req_as_id: answer?.req_as_id,
      req_as_req_id: req_id,
      req_as_sell_price: parseFloat(prix_vente),
      req_as_buying_price: parseFloat(prix_achat),
      req_as_tva: tva,
      req_as_partial: partiel ? parseFloat(partiel) : 0,
      req_as_gross_margin: parseFloat(marge_brute),
      req_as_margin_rate: parseFloat(taux_marge),
      req_as_rate_mark: parseFloat(taux_marque),
      req_as_fees_miscellaneous_name: nom_frais_divers,
      req_as_fees_miscellaneous_cost: parseFloat(montant_frais_divers),
      req_as_user_id_create: _idUser,
      req_as_user_id_update: _idUser,
      sous_traitant: subcontractor,
    };
    try {
      let notif;

      if (JSON.stringify(answer) !== "[]") {
        const { loading, data, errors } = await FetchData(
          `/api/request/response/save/${parseInt(answer?.req_as_id)}`,
          {
            method: "PUT",
            body: JSON.stringify(newDemandResponse),
          }
        );
        if (data?.status === 200) {
          notif = {
            title: "Succès",
            text: "Réponse bien modifiée.",
            icon: "success",
          };
          getData();
          resetError();
        } else {
          const { erreur } = data;
          notif = {
            title: "Erreur!",
            text: data.error,
            icon: "error",
          };
        }
      } else {
        const { loading, data, errors } = await FetchData(
          "/api/request/response/save",
          {
            method: "POST",
            body: JSON.stringify(newDemandResponse),
          }
        );
        if (data?.status === 200) {
          notif = {
            title: "Succès",
            text: "Réponse bien enregistrée.",
            icon: "success",
          };
          getData();
          resetError();
        } else {
          const { erreur } = data;
          notif = {
            title: "Erreur!",
            text: data.error,
            icon: "error",
          };
        }
      }
      Swal.fire({
        title: notif.title,
        text: notif.text,
        icon: notif.icon,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  useEffect(() => {
    const tauxMarque = (((prix_vente - prix_achat) / prix_vente) * 100).toFixed(
      3
    );
    const tauxMarge = (((prix_vente - prix_achat) / prix_achat) * 100).toFixed(
      3
    );
    const margeBrut = (prix_vente - prix_achat).toFixed(3);

    set_taux_marque(isFinite(tauxMarque) && tauxMarque >= 0 ? tauxMarque : 0);
    set_taux_marge(isFinite(tauxMarge) && tauxMarge >= 0 ? tauxMarge : 0);
    set_marge_brute(isFinite(margeBrut) ? margeBrut : 0);
  }, [prix_achat, prix_vente]);

  useEffect(() => {
    let sum = 0;
    subcontractor.forEach((p) => {
      sum += p.req_ast_cost;
    });
    sum += isNaN(parseFloat(montant_frais_divers))
      ? 0
      : parseFloat(montant_frais_divers);
    set_prix_achat(sum);
  }, [subcontractor, montant_frais_divers]);

  return (
    <>
      <Form
        formData={{
          type: "form",
          id: "addCollaborator",
          formMethod: "post",
          formAction: "#",
          formClass: "",
        }}
        formRef={formRef}
      >
        {!isUpdate ? (
          <>
            {[3, 4, 5].includes(_demande.req_id_status) ||
            !can_update_answer ? (
              <div className="mt-4"></div>
            ) : (
              <div className=" container-fluid d-flex mt-2 mb-2  ps-4 pt-3 pb-3 justify-content-end ">
                <FormButton
                  buttonType="button"
                  buttonValue="Modifier"
                  buttonClass="btn btn-outline-primary input-label-size me-2"
                  hasIcon={true}
                  iconClass="fas fa-pen me-2"
                  loseModal={false}
                  buttonOnClick={() => set_isUpdate(true)}
                />
              </div>
            )}
            <DetailResponseDemande
              answer={answer}
              responsable={responsable}
              _data_list={_data_list}
            />
          </>
        ) : (
          <>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Général",
                sectionClass: "border-bottom",
              }}
            >
              <Input
                type={"text"}
                blockClass={"mb-3 me-2 col"}
                id={"responsable"}
                labelClass={""}
                label={"Responsable"}
                inputClass=""
                placeholder="Responsable"
                isRequired={false}
                isDisabled={true}
                errorMessage=""
                value={responsable}
                onChange={set_responsable}
              />
              <div className="d-flex flex-row">
                <Input
                  type="date"
                  blockClass="mb-3 me-2 col-4"
                  id="date_reponse"
                  labelClass=""
                  label="Date de réponse"
                  placeholder="Date de réponse"
                  inputClass="custom-input-colors"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["date_reponse"]}
                  value={date_reponse}
                  onChange={set_date_reponse}
                />
                <Input
                  type="time"
                  blockClass="mb-3 me-2 col-4"
                  id="heure_reponse"
                  labelClass=""
                  label="Heure de réponse"
                  placeholder="Heure de réponse"
                  inputClass="custom-input-colors"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["heure_reponse"]}
                  value={heure_reponse}
                  onChange={set_heure_reponse}
                />
              </div>
            </FormSection>
            <FormSection
              formSectionData={{ type: "section", title: "Sous traitants" }}
            >
              <SelectBox
                type="select"
                blockClass="mb-3 me-2 col"
                id="nb_sous_traitants"
                labelClass=""
                label="Nombre de sous traitants"
                selectClass="input-label-size "
                isRequired={true}
                selectOptions={sous_traitant_nombres}
                cle="id"
                value="label"
                placeholder="Nombre de sous traitants"
                value_selected={nb_sous_traitants}
                errorMessage={error_message["nb_sous_traitants"]}
                onChange={set_nb_sous_traitants}
              />

              {subcontractor.map((item_s, index) => (
                <div className="d-flex flex-row" key={index}>
                  <SelectBox
                    type="select"
                    blockClass="mb-3 me-2 col"
                    id={`subcontractor_id_${index + 1}`}
                    labelClass=""
                    label={`Sous traitants ${index + 1}`}
                    selectClass="input-label-size "
                    isRequired={true}
                    selectOptions={_sous_traitans}
                    cle="st_id"
                    value="st_company_name"
                    placeholder={`Sous traitants ${index + 1}`}
                    value_selected={item_s?.req_ast_subcontractor_id}
                    errorMessage={errorSub[index][`error_sub`]}
                    onChange={(e) => {
                      handleChangeSubstractor(e, index);
                    }}
                  />
                  <Input
                    type="number"
                    blockClass="mb-3 me-2 col"
                    id={`req_ast_cost_id_${index}`}
                    labelClass=""
                    label="Coût"
                    inputClass="custom-input-colors"
                    placeholder="Coût"
                    isRequired={true}
                    isDisabled={false}
                    value={item_s?.req_ast_cost}
                    errorMessage={errorSub[index][`error_cost`]}
                    onChange={(e) => {
                      handleChangeSubCost(e, index);
                    }}
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id={`req_ast_ref_id_${index}`}
                    labelClass=""
                    label="Référence"
                    inputClass="custom-input-colors"
                    placeholder="Référence"
                    isRequired={false}
                    isDisabled={false}
                    value={item_s?.req_ast_ref}
                    errorMessage={errorSub[index][`error_ref`]}
                    onChange={(e) => {
                      handleChangeSubRef(e, index);
                    }}
                    suffix=""
                  />
                </div>
              ))}
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Proposition commérciale",
              }}
            >
              <div className="d-flex flex-row">
                <Input
                  type="number"
                  blockClass="mb-3 me-2 col"
                  id="prix_vente"
                  labelClass=""
                  label="Prix de vente"
                  inputClass="custom-input-colors"
                  placeholder="Prix de vente"
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["prix_vente"]}
                  value={prix_vente}
                  onChange={set_prix_vente}
                />
                <Input
                  type="number"
                  blockClass="mb-3 me-2 col"
                  id="prix_achat"
                  labelClass=""
                  label="Prix d'achat"
                  inputClass="custom-input-colors"
                  placeholder="Prix d'achat"
                  isRequired={true}
                  isDisabled={true}
                  value={prix_achat}
                />
              </div>
              <div className={"mb-3 mb-3 me-2 col"}>
                <label
                  className={"form-label input-label-size position-relative "}
                >
                  TVA
                  <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
                    *
                  </span>
                </label>
                <div className="input-group">
                  <div className="d-flex flex align-items-center">
                    <RadioButton
                      name="tvaOption"
                      label="TVA"
                      options={[
                        { key: 0, label: "Oui" },
                        { key: 1, label: "Non" },
                        { key: 2, label: "Partielle" },
                      ]}
                      selected={tva}
                      onChange={(e) => handleNoTva(e)}
                    />

                    <Input
                      type="number"
                      blockClass="input-group col "
                      id="partiel"
                      labelClass="none"
                      label=""
                      inputClass="custom-input-colors "
                      placeholder="0"
                      isRequired={tva != 1}
                      isDisabled={false}
                      value={partiel}
                      errorMessage={error_message["partiel"]}
                      onChange={(e) => handleTVAInput(e)}
                      suffix="%"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row">
                <Input
                  type="number"
                  blockClass="mb-3 me-2 col"
                  id="marge_brute"
                  labelClass=""
                  label="Marge brute"
                  inputClass="custom-input-colors"
                  placeholder="Marge brute"
                  isRequired={false}
                  isDisabled={true}
                  value={marge_brute}
                />
                <Input
                  type="number"
                  blockClass="mb-3 me-2 col"
                  id="taux_marge"
                  labelClass=""
                  label="Taux marge"
                  inputClass="custom-input-colors"
                  placeholder="Taux marge"
                  isRequired={false}
                  isDisabled={true}
                  value={taux_marge}
                  suffix="%"
                />
                <Input
                  type="number"
                  blockClass="mb-3 me-2 col"
                  id="taux_marque"
                  labelClass=""
                  label="Taux marque"
                  inputClass="custom-input-colors"
                  placeholder="Taux marque"
                  isRequired={false}
                  isDisabled={true}
                  value={taux_marque}
                  suffix="%"
                />
              </div>
            </FormSection>
            <FormSection
              formSectionData={{
                type: "section",
                title: "Frais divers",
              }}
            >
              <div className="d-flex flex-row">
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col-4"
                  id="nom_frais_divers"
                  labelClass=""
                  label="Nom frais divers"
                  placeholder="Nom du frais divers"
                  inputClass="custom-input-colors"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage=""
                  value={nom_frais_divers}
                  onChange={set_nom_frais_divers}
                />
                <Input
                  type="number"
                  blockClass="mb-3 me-2 col-4"
                  id="montant_frais_divers"
                  labelClass=""
                  label="Montant frais divers"
                  placeholder="Montant du frais divers"
                  inputClass="custom-input-colors"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage=""
                  value={montant_frais_divers}
                  onChange={set_montant_frais_divers}
                />
              </div>
            </FormSection>
            <div
              className={"d-flex justify-content-end mt-2 mb-- modal-footer"}
            >
              <FormButton
                buttonType="button"
                buttonValue="Annuler"
                buttonClass="btn text-secondary input-label-size me-2"
                hasIcon={true}
                iconClass="far fa-times-circle me-2 bg-light"
                closeModal={true}
                buttonOnClick={resetError}
              />
              {(answer?.length === 0 || _demande?.req_id_status === 1) && (
                <FormButton
                  buttonValue="Sauvegarder"
                  buttonClass="btn btn-primary input-label-size me-2"
                  hasIcon={true}
                  iconClass="fas fa-check me-2"
                  closeModal={false}
                  buttonOnClick={(e) => {
                    onSaveDemande(e);
                  }}
                />
              )}

              <FormButton
                buttonValue={
                  _demande?.req_id_status === 2
                    ? "Modifier Réponse"
                    : "Répondre"
                }
                buttonClass="btn green-button-bsl input-label-size me-2"
                hasIcon={true}
                iconClass="fas fa-check me-2"
                closeModal={false}
                buttonOnClick={(e) => {
                  onSubmitResponsDemande(e);
                }}
              />
            </div>
          </>
        )}
      </Form>
      <TemplateEmail
        isShowModale={show_mail}
        modalId="template-mail-reponse-demande"
        modalClass=" "
        idUser={_idUser}
        showTemplateEmail={set_show_mail}
        closeResponse={() => {
          handleCloseModal();
          _setIsOnResponsDemand(false);
        }}
        demande={_demande}
        answer={current_answer}
        mail_data={mail_data}
        getData={getData}
      />
    </>
  );
};

export default RepondreDemande;
