import React, { useState, useRef, useEffect } from "react";
import FormButton from "../../../Form/FormButton";
import FormSection from "../../../Form/FormSection";
import Input from "../../../Form/Input";
import TextArea from "../../../Form/TextArea";
import Form from "../../../Form/Form";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import SelectBox from "../../../Form/SelectBox";

const Relance = ({
  handleCloseModal,
  idUser,
  _demande,
  relance,
  can_add_relance = true,
  getDemande = () => {},
  justShow = false,
  all_canal,
}) => {
  const formRef = useRef(null);
  const [showAddRelance, setShowAddRelance] = useState(can_add_relance);
  const [date_relance, set_date_relance] = useState("");
  const [time_relance, set_time_relance] = useState("");
  const [comment_relance, set_comment_relance] = useState("");
  const [canal, set_canal] = useState("");

  const [error_message, setError_message] = useState({
    canal: "",
  });

  const required_select = ["canal"];

  const resetError = () => {
    setError_message({
      canal: "",
    });
    set_canal("");
    set_comment_relance("");

    setShowAddRelance(true);
  };

  const getDate = async () => {
    const { loading, data, errors } = await FetchData(
      "/api/request/date-creation",
      {
        method: "GET",
      }
    );
    if (data.status === 200) {
      set_date_relance(data.data.date?.split("/").reverse().join("-"));
      set_time_relance(data.data.time);
    } else {
      Swal.fire({
        title: "Erreur!",
        text: data?.error,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const addRelance = async (e) => {
    e.preventDefault();

    const inputs = new FormData(formRef.current);
    let errors = error_message;
    let validate = true;

    required_select.forEach((key) => {
      if (!inputs.get(key) && inputs.get(key) === null) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };

        validate = false;

        const errorElement = document.getElementById(key);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        errors[key] = "";
        errors = { ...errors, key: "" };
      }
    });

    setError_message(errors);

    if (validate) {
      let notif;
      const newRelance = {
        rr_req_id: _demande.req_id,
        rr_date_reminder: date_relance,
        rr_time_reminder: time_relance,
        rr_comment: comment_relance,
        rr_user_id_create: idUser,
        rr_rrc_id: parseInt(canal),
      };
      try {
        const { loading, data, errors } = await FetchData(
          `/api/request/reminder`,
          {
            method: "POST",
            body: JSON.stringify(newRelance),
          }
        );
        if (data?.status === 200) {
          notif = {
            title: "Succès",
            text: "Relance bien ajoutée.",
            icon: "success",
          };
          resetError();
          getDemande(_demande.req_id);
        } else {
          const { erreur } = data;
          notif = {
            title: "Erreur!",
            text: data.error,
            icon: "error",
          };
        }
        Swal.fire({
          title: notif.title,
          text: notif.text,
          icon: notif.icon,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
      }
    } else {
      validate = true;
    }
  };

  return (
    <>
      <Form
        formData={{
          type: "form",
          id: "addRelance",
          formMethod: "post",
          formAction: "#",
          formClass: "",
        }}
        formRef={formRef}
      >
        <div
          className="z-2 position-absolute  align-items-center "
          style={{ minWidth: "100%", maxWidth: "400px" }}
        >
          {showAddRelance
            ? !justShow && (
                <div className=" container-fluid d-flex mt-2 mb-2  ps-4 pt-3 pb-3 justify-content-end ">
                  <FormButton
                    buttonType="button"
                    buttonValue="Ajouter une relance"
                    buttonClass="btn btn-outline-primary input-label-size me-2"
                    hasIcon={true}
                    iconClass="fas fa-pen-nib"
                    loseModal={false}
                    buttonOnClick={() => {
                      setShowAddRelance(false);
                      getDate();
                    }}
                  />
                </div>
              )
            : can_add_relance && (
                <FormSection
                  formSectionData={{
                    type: "section",
                    title: "Relance",
                    sectionClass: "border-bottom",
                  }}
                >
                  <div className="d-flex flex-row">
                    <SelectBox
                      type="select"
                      blockClass="mb-3 me-2 col-3"
                      id="canal"
                      labelClass=""
                      label="Canal"
                      selectClass="input-label-size "
                      isRequired={true}
                      selectOptions={all_canal}
                      cle="rrc_id"
                      value="rrc_name"
                      placeholder="Canal"
                      value_selected={canal}
                      onChange={set_canal}
                      errorMessage={error_message["canal"]}
                    />
                  </div>
                  <div className="d-flex flex-row">
                    <Input
                      type="date"
                      blockClass="mb-3 me-2 col"
                      id="date_relance"
                      labelClass="text-muted"
                      label="Date"
                      inputClass="custom-input-colors"
                      placeholder="PosDatete"
                      isRequired={false}
                      isDisabled={true}
                      errorMessage={error_message["date_relance"]}
                      value={date_relance}
                      onChange={set_date_relance}
                    />
                    <Input
                      type="time"
                      blockClass="mb-3 me-2 col"
                      id="time_relance"
                      labelClass="text-muted"
                      label="Heure"
                      inputClass="custom-input-colors"
                      placeholder="Heure"
                      isRequired={false}
                      isDisabled={true}
                      errorMessage={error_message["time_relance"]}
                      value={time_relance}
                      onChange={set_time_relance}
                    />
                  </div>
                  <TextArea
                    blockClass={"mb-3 me-2 col"}
                    id={"comment_relance"}
                    labelClass={""}
                    label={"Commentaire"}
                    inputClass=""
                    placeholder="Commentaire"
                    isRequired={true}
                    value={comment_relance}
                    onChange={set_comment_relance}
                  />
                </FormSection>
              )}
          {relance?.map((r, i) => (
            <div key={i} className="p-2">
              <div className="container-fluid container-sm">
                <div
                  className="d-flex align-items-center gap-3"
                  style={{ minHeight: "150px" }}
                >
                  <div
                    className={`rounded rounded-circle bg-primary input-text-size text-white pt-1 ps-2 pe-2 ${
                      false
                        ? "border border-light border-5 border-opacity-75"
                        : "border border-light border-5 border-opacity-100"
                    }`}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                    }}
                  >
                    {r.rr_id}
                  </div>
                  <div
                    className="bg-light"
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      wordWrap: "break-word",
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex flex-row">
                        <div className="mb-2 col">
                          <p className="h6 mt-0 mb-0">Relance {r.rr_id}</p>
                          <span className="text-muted mt-0 small">
                            Le {r.rr_date_reminder} à {r.rr_time_reminder}
                          </span>
                        </div>
                        <div className="mb-2 col justify-content-end">
                          <p className="mt-0 small text-end">
                            Canal : {all_canal[0 + r?.rr_rrc_id - 1].rrc_name}
                          </p>
                        </div>
                      </div>
                      <p className="text-muted small mb-0"> {r.rr_comment}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {!showAddRelance && (
          <div
            className="position-fixed bottom-0 start-0 w-100 d-flex justify-content-end mb-4 z-2 "
            style={{ marginLeft: "-30px" }}
          >
            <FormButton
              buttonType="button"
              buttonValue="Annuler"
              buttonClass="btn text-secondary input-label-size me-2 bg-light"
              hasIcon={true}
              iconClass="far fa-times-circle me-2 bg-light"
              buttonOnClick={() => {
                setShowAddRelance(true);
                resetError();
              }}
            />
            <FormButton
              buttonValue="Ajouter la relance"
              buttonClass="btn green-button-bsl input-label-size me-2"
              hasIcon={true}
              iconClass="fas fa-check me-2"
              closeModal={false}
              buttonOnClick={(e) => {
                addRelance(e);
              }}
            />
          </div>
        )}
      </Form>
    </>
  );
};

export default Relance;
