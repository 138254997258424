import React, { useEffect, useState, useRef } from "react";
import ModalForAddNew from "../../ModalForAddNew";
import HeaderModal from "./HeaderModal";
import InputFile from "../../../Form/InputFile";
import "../../../../styles/Form/modal_update.css";
import DetailDemande from "./detailDemande";
import Swal from "sweetalert2";
import { FetchData } from "../../../../hooks/FetchData";
import RepondreDemande from "./RepondreDemande";
import Form from "../../../Form/Form";
import Invalide from "./Invalide";
import Relance from "./Relance";
import ActionAMene from "./ActionAMene";
import ShowLogDemande from "./ShowLogDemande";
import { useAccess } from "../../../../hooks/useAccess";

const UpdateDevis = ({
  action = [],
  all_action,
  get_all_action,
  getDemande,
  relance,
  answer,
  idUser,
  demande,
  sous_traitans,
  getData,
  modalData = {},
  isShowModal = false,
  handleCloseModal = function () {},
  data_list,
  all_canal,
  data_country_list = [],
  data_log = [],
}) => {
  //Permissions
  const can_update_in_progress_request = useAccess(62);
  const can_update_answered_request = useAccess(65);
  const can_create_relance_answered_request = useAccess(66);
  const can_create_action_answered_request = useAccess(67);
  const can_update_action_answered_request = useAccess(85);
  const can_delete_action_answered_request = useAccess(68);
  const can_validate_answered_request = useAccess(69);
  const can_abort_answered_request = useAccess(70);
  const can_refuse_answered_request = useAccess(71);
  const can_see_request_log = useAccess(74);

  const [logData, setLogData] = useState([]);
  useEffect(() => {
    setLogData(data_log);
  }, [data_log]);
  const form_data = {
    type: "form",
    id: "updateDemande",
    formClass: "",
  };
  const formRef = useRef(null);

  const [activeTab, setActiveTab] = useState(1);
  const [status, setStatus] = useState(0);
  const [_demande, set_demande] = useState(demande);
  const [_sous_traitans, set_sous_traitans] = useState(sous_traitans);
  const [update_action, set_update_action] = useState("");
  const [show_notif, set_show_notif] = useState(false);

  const show_log = (id) => {
    return can_see_request_log
      ? [
          {
            id: id,
            name: "Historiques",
            content: (
              <>
                <ShowLogDemande data_log={logData} />
              </>
            ),
          },
        ]
      : [];
  };

  useEffect(() => {
    set_demande(demande);
    setStatus(demande?.req_id_status);

    let date_url = new Date(demande?.req_created_at);
    date_url =
      date_url.getFullYear() +
      "-" +
      (date_url.getMonth() + 1).toString().padStart(2, "0");

    const url_email = `/api/email/${date_url}/`;
    const url_doc = `/api/document/${date_url}/`;

    set_req_file_email((v) =>
      demande?.req_file_email?.map((d) => ({
        filename: d,
        url: url_email + d,
      }))
    );
    set_req_file_image((v) =>
      demande?.req_file_image.map((d) => ({
        filename: d,
        url: url_doc + d,
      }))
    );
  }, [demande]);

  useEffect(() => {
    try {
      let date_url = new Date(answer?.req_as_created_at);
      date_url =
        date_url.getFullYear() +
        "-" +
        (date_url.getMonth() + 1).toString().padStart(2, "0");

      const url_email = `/api/response/${date_url}/${answer?.req_as_id}/`;
      const url_doc = `/api/response/${date_url}/${answer?.req_as_id}/`;
      const req_as_mail_name_exists =
        req_file_email?.filter(
          (file) => file.filename == answer?.req_as_mail_name
        )?.length > 0;
      if (answer?.req_as_mail_name && !req_as_mail_name_exists) {
        set_req_file_email((e) => [
          ...e,
          {
            filename: answer?.req_as_mail_name,
            url: url_email + answer?.req_as_mail_name,
          },
        ]);
      }

      const req_as_pdf_name_exists =
        req_file_image?.filter(
          (file) => file.filename == answer?.req_as_pdf_name
        )?.length > 0;
      if (answer?.req_as_pdf_name && !req_as_pdf_name_exists) {
        set_req_file_image((e) => [
          ...e,
          {
            filename: answer?.req_as_pdf_name,
            url: url_doc + answer?.req_as_pdf_name,
          },
        ]);
      }
    } catch (error) {
      console.error(
        "Erreur dans le chargement de la liste des mails/pdf :\n",
        error
      );
    }
  }, [answer]);

  useEffect(() => {
    set_sous_traitans(sous_traitans);
  }, [sous_traitans]);

  const [isUpdate, setIsUpdate] = useState(false);
  const [isOnResponsDemand, setIsOnResponsDemand] = useState(false);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  //a factoriser dans un fichier
  const [req_file_email, set_req_file_email] = useState([]);
  const [req_file_image, set_req_file_image] = useState([]);

  const handleChangeEmail = async (d_file) => {
    const formData = new FormData();
    formData.append("file", d_file);
    const { loading, data, errors } = await FetchData(
      `/api/request/upload/email/${idUser}`,
      {
        method: "PUT",
        body: formData,
      },
      true
    );
    if (data.status === 200) {
      demande && set_req_file_email((v) => [...v, data.data]?.filter((d) => d));
    } else {
      Swal.fire({
        title: "Erreur!",
        text: data.error,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const onDeleteEmail = (name) => {
    set_req_file_email(req_file_email.filter((d) => d.filename != name));
  };

  const handleChangeDocument = async (d_file) => {
    const formData = new FormData();
    formData.append("file", d_file);
    const { loading, data, errors } = await FetchData(
      `/api/request/upload/doc/${idUser}`,
      {
        method: "PUT",
        body: formData,
      },
      true
    );
    if (errors) {
      Swal.fire({
        title: "Erreur!",
        text: errors,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      if (data.status === 200) {
        demande &&
          set_req_file_image((v) => [...v, data.data]?.filter((d) => d));
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const submitAddDevis = async (e) => {
    e.preventDefault();
    _demande.req_date_request = `${_demande?.req_date_request?.split("/")[2]}-${
      _demande?.req_date_request?.split("/")[1]
    }-${_demande?.req_date_request?.split("/")[0]}`;
    const newrequest = {
      req_file_email: req_file_email
        ?.filter((d) => !d.filename.includes(answer?.req_as_mail_name))
        .map((d) => d.filename),
      req_file_image: req_file_image
        ?.filter(
          (d) => !d.filename.includes(answer?.req_as_pdf_name?.split("-")[0])
        )
        .map((d) => d.filename),
      req_user_id_create: idUser,
      req_user_id_update: idUser,
      req_title: _demande?.req_title,
      req_client_id: _demande?.req_client_id,
      req_client_ref: _demande?.reference_client,
      req_date_request: _demande?.req_date_request,
      req_time_request: _demande?.req_time_request,
      req_client_contact_id: _demande?.req_client_contact_id,
      req_origin_company: _demande?.req_origin_company,
      req_origin_country: _demande?.req_origin_country,
      req_origin_zip_code: _demande?.req_origin_zip_code,
      req_origin_city: _demande?.req_origin_city,
      req_origin_adress: _demande?.req_origin_adress,
      req_dest_company: _demande?.req_dest_company,
      req_dest_country: _demande?.req_dest_country,
      req_dest_zip_code: _demande?.req_dest_zip_code,
      req_dest_city: _demande?.req_dest_city,
      req_dest_adress: _demande?.req_dest_adress,
      req_goods_nb_colis: _demande?.req_goods_nb_colis,
      req_goods_gross_weight: _demande?.req_goods_gross_weight,
      // req_goods_volumetric_weight,
      req_goods_height: _demande?.req_goods_height,
      req_goods_length: _demande?.req_goods_length,
      req_goods_width: _demande?.req_goods_width,
      //parser
      req_fret_taxable_weight: _demande?.req_fret_taxable_weight,
      req_weight_taxable_integrator: _demande?.req_weight_taxable_integrator,
      req_goods_ref_dbl: _demande?.req_goods_ref_dbl,
      req_goods_description: _demande?.req_goods_description,
    };
    try {
      let url = "",
        options = {},
        message = {};
      if (demande?.req_id > 0) {
        url = `/api/request/${demande?.req_id}`;
        options = {
          method: "PUT",
          body: JSON.stringify(newrequest),
        };
        message = {
          title: "Succès",
          text: "Modification du fichier réussie",
        };
      }
      const { loading, data, errors } = await FetchData(url, options);
      if (data.status === 200) {
        Swal.fire({
          ...message,
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        setIsUpdate(false);
        handleCloseModal();
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const onDeleteDoc = (name) => {
    set_req_file_image(req_file_image.filter((d) => d.filename != name));
  };
  let data_tab = [];

  useEffect(() => {
    if (demande?.req_id_status == 2) {
      set_show_notif(action?.length < 1);
    } else {
      set_show_notif(action.some((a) => !a.rla_done));
    }
  }, [action]);

  switch (status) {
    case 1:
      data_tab = [
        {
          id: 1,
          name: "Demande initiale",
          content: (
            <>
              <DetailDemande
                _demande={_demande}
                _isUpdate={isUpdate}
                can_update_request={can_update_in_progress_request}
                idUser={idUser}
                _setIsUpdate={setIsUpdate}
                _data_list={data_list}
                data_country_list={data_country_list}
                getDemande={getDemande}
                getData={getData}
              />
            </>
          ),
        },
        {
          id: 2,
          name: "Mail",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "eml", icone: "fa fa-envelope" },
                    { fileType: "email", icone: "fa fa-file" },
                    { fileType: "oft", icone: "fa fa-envelope" },
                    { fileType: "msg", icone: "fa fa-envelope" },
                  ]}
                  name_input="file"
                  size_file={100}
                  set_document={handleChangeEmail}
                  data_file={req_file_email}
                  title="Mails ajoutés"
                  deleteFile={onDeleteEmail}
                  isUpdate={
                    _demande && !isUpdate && can_update_in_progress_request
                  }
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={isUpdate}
                />
              </Form>
            </>
          ),
        },
        {
          id: 3,
          name: "Documents",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "pdf", icone: "fa fa-envelope" },
                    { fileType: "docx", icone: "fa fa-file" },
                    { fileType: "jpeg", icone: "fa fa-thin fa-image" },
                    { fileType: "jpg", icone: "fa fa-thin fa-image" },
                    { fileType: "png", icone: "fa fa-thin fa-image" },
                  ]}
                  name_input="file"
                  size_file={1}
                  set_document={handleChangeDocument}
                  title="Documents ajoutés"
                  data_file={req_file_image}
                  deleteFile={onDeleteDoc}
                  isUpdate={
                    _demande && !isUpdate && can_update_in_progress_request
                  }
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={isUpdate}
                />
              </Form>
            </>
          ),
        },
        ...show_log(4),
      ];
      break;
    case 2:
      data_tab = [
        {
          id: 1,
          name: "Demande initiale",
          content: (
            <>
              <DetailDemande
                _demande={_demande}
                _isUpdate={isUpdate}
                can_update_request={
                  can_update_in_progress_request && can_update_answered_request
                }
                idUser={idUser}
                _setIsUpdate={setIsUpdate}
                _data_list={data_list}
                data_country_list={data_country_list}
                getDemande={getDemande}
                getData={getData}
              />
            </>
          ),
        },
        {
          id: 2,
          name: "Réponse",
          content: (
            <>
              <RepondreDemande
                _setIsOnResponsDemand={setIsOnResponsDemand}
                _demande={_demande}
                can_update_answer={can_update_answered_request}
                _idUser={idUser}
                handleCloseModal={handleCloseModal}
                _sous_traitans={_sous_traitans}
                answer={answer}
                getData={getData}
                isShowUpdate={false}
                _data_list={data_list}
              />
            </>
          ),
        },
        {
          id: 3,
          name: "Relance",
          content: (
            <>
              <Relance
                handleCloseModal={handleCloseModal}
                idUser={idUser}
                _demande={_demande}
                relance={relance}
                can_add_relance={
                  can_update_answered_request &&
                  can_create_relance_answered_request
                }
                getDemande={getDemande}
                all_canal={all_canal}
              />
            </>
          ),
        },
        {
          id: 4,
          name: "Actions à mener",
          show_notif: show_notif,
          content: (
            <>
              <ActionAMene
                idUser={idUser}
                _demande={_demande}
                all_action={all_action}
                can_add_action={
                  can_update_answered_request &&
                  can_create_action_answered_request
                }
                can_delete_action={
                  can_update_answered_request &&
                  can_delete_action_answered_request
                }
                can_update_action={
                  can_update_answered_request &&
                  can_update_action_answered_request
                }
                get_all_action={get_all_action}
                action={action}
                set_update_action={set_update_action}
                update_action={update_action}
                getData={getData}
                set_show_notif={set_show_notif}
                handleCloseModal={handleCloseModal}
              />
            </>
          ),
        },
        {
          id: 5,
          name: "Mails",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "eml", icone: "fa fa-envelope" },
                    { fileType: "email", icone: "fa fa-file" },
                    { fileType: "oft", icone: "fa fa-envelope" },
                    { fileType: "msg", icone: "fa fa-envelope" },
                  ]}
                  name_input="file"
                  size_file={100}
                  set_document={handleChangeEmail}
                  data_file={req_file_email}
                  title="Mails ajoutés"
                  deleteFile={onDeleteEmail}
                  isUpdate={
                    _demande &&
                    !isUpdate &&
                    can_update_in_progress_request &&
                    can_update_answered_request
                  }
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={true}
                />
              </Form>
            </>
          ),
        },
        {
          id: 6,
          name: "Documents",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "pdf", icone: "fa fa-envelope" },
                    { fileType: "docx", icone: "fa fa-file" },
                    { fileType: "jpeg", icone: "fa fa-thin fa-image" },
                    { fileType: "jpg", icone: "fa fa-thin fa-image" },
                    { fileType: "png", icone: "fa fa-thin fa-image" },
                  ]}
                  name_input="file"
                  size_file={1}
                  set_document={handleChangeDocument}
                  title="Documents ajoutés"
                  data_file={req_file_image}
                  deleteFile={onDeleteDoc}
                  isUpdate={
                    _demande &&
                    !isUpdate &&
                    can_update_in_progress_request &&
                    can_update_answered_request
                  }
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={true}
                />
              </Form>
            </>
          ),
        },
        ...show_log(7),
      ];
      break;
    case 3:
      data_tab = [
        {
          id: 1,
          name: "Demande initiale",
          content: (
            <>
              <DetailDemande
                _demande={_demande}
                _isUpdate={false}
                idUser={idUser}
                _setIsUpdate={setIsUpdate}
                _data_list={data_list}
                data_country_list={data_country_list}
                getDemande={getDemande}
              />
            </>
          ),
        },
        {
          id: 2,
          name: "Réponse",
          content: (
            <>
              <RepondreDemande
                _setIsOnResponsDemand={setIsOnResponsDemand}
                _demande={_demande}
                _idUser={idUser}
                handleCloseModal={handleCloseModal}
                _sous_traitans={_sous_traitans}
                answer={answer}
                getData={getData}
                isShowUpdate={false}
                _data_list={data_list}
              />
            </>
          ),
        },
        {
          id: 3,
          name: "Relance",
          content: (
            <>
              <Relance
                handleCloseModal={handleCloseModal}
                idUser={idUser}
                _demande={_demande}
                relance={relance}
                getDemande={getDemande}
                all_canal={all_canal}
                justShow={true}
              />
            </>
          ),
        },
        {
          id: 4,
          name: "Actions à mener",
          show_notif: show_notif,
          content: (
            <>
              <ActionAMene
                idUser={idUser}
                _demande={_demande}
                all_action={all_action}
                get_all_action={get_all_action}
                action={action}
                set_update_action={set_update_action}
                update_action={update_action}
                getData={getData}
                set_show_notif={set_show_notif}
                handleCloseModal={handleCloseModal}
                getDemande={getDemande}
              />
            </>
          ),
        },
        {
          id: 5,
          name: "Mails",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "eml", icone: "fa fa-envelope" },
                    { fileType: "email", icone: "fa fa-file" },
                    { fileType: "oft", icone: "fa fa-envelope" },
                    { fileType: "msg", icone: "fa fa-envelope" },
                  ]}
                  name_input="file"
                  size_file={100}
                  set_document={handleChangeEmail}
                  data_file={req_file_email}
                  title="Mails ajoutés"
                  deleteFile={onDeleteEmail}
                  isUpdate={_demande && !isUpdate}
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                  justeShow={false}
                />
              </Form>
            </>
          ),
        },
        {
          id: 6,
          name: "Documents",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "pdf", icone: "fa fa-envelope" },
                    { fileType: "docx", icone: "fa fa-file" },
                    { fileType: "jpeg", icone: "fa fa-thin fa-image" },
                    { fileType: "jpg", icone: "fa fa-thin fa-image" },
                    { fileType: "png", icone: "fa fa-thin fa-image" },
                  ]}
                  name_input="file"
                  size_file={1}
                  set_document={handleChangeDocument}
                  title="Documents ajoutés"
                  data_file={req_file_image}
                  deleteFile={onDeleteDoc}
                  isUpdate={_demande && !isUpdate}
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                  justeShow={false}
                />
              </Form>
            </>
          ),
        },
        ...show_log(7),
      ];
      break;
    case 4:
      data_tab = [
        {
          id: 1,
          name: "Demande initiale",
          content: (
            <>
              <DetailDemande
                _demande={_demande}
                _isUpdate={false}
                idUser={idUser}
                _setIsUpdate={setIsUpdate}
                _data_list={data_list}
                data_country_list={data_country_list}
                getDemande={getDemande}
              />
            </>
          ),
        },
        {
          id: 2,
          name: "Réponse",
          content: (
            <>
              <RepondreDemande
                _setIsOnResponsDemand={setIsOnResponsDemand}
                _demande={_demande}
                _idUser={idUser}
                handleCloseModal={handleCloseModal}
                _sous_traitans={_sous_traitans}
                answer={answer}
                getData={getData}
                isShowUpdate={false}
                _data_list={data_list}
              />
            </>
          ),
        },
        {
          id: 3,
          name: "Relance",
          content: (
            <>
              <Relance
                handleCloseModal={handleCloseModal}
                idUser={idUser}
                _demande={_demande}
                relance={relance}
                getDemande={getDemande}
                all_canal={all_canal}
                justShow={true}
              />
            </>
          ),
        },
        {
          id: 4,
          name: "Mails",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "eml", icone: "fa fa-envelope" },
                    { fileType: "email", icone: "fa fa-file" },
                    { fileType: "oft", icone: "fa fa-envelope" },
                    { fileType: "msg", icone: "fa fa-envelope" },
                  ]}
                  name_input="file"
                  size_file={100}
                  set_document={handleChangeEmail}
                  data_file={req_file_email}
                  title="Mails ajoutés"
                  deleteFile={onDeleteEmail}
                  isUpdate={_demande && !isUpdate}
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                  justeShow={false}
                />
              </Form>
            </>
          ),
        },
        {
          id: 5,
          name: "Documents",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "pdf", icone: "fa fa-envelope" },
                    { fileType: "docx", icone: "fa fa-file" },
                    { fileType: "jpeg", icone: "fa fa-thin fa-image" },
                    { fileType: "jpg", icone: "fa fa-thin fa-image" },
                    { fileType: "png", icone: "fa fa-thin fa-image" },
                  ]}
                  name_input="file"
                  size_file={1}
                  set_document={handleChangeDocument}
                  title="Documents ajoutés"
                  data_file={req_file_image}
                  deleteFile={onDeleteDoc}
                  isUpdate={_demande && !isUpdate}
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                  justeShow={false}
                />
              </Form>
            </>
          ),
        },
        {
          id: 6,
          name: "Annulation",
          content: (
            <>
              <Invalide _demande={_demande} />
            </>
          ),
        },
        ...show_log(7),
      ];
      break;
    case 5:
      data_tab = [
        {
          id: 1,
          name: "Demande initiale",
          content: (
            <>
              <DetailDemande
                _demande={_demande}
                _isUpdate={false}
                idUser={idUser}
                _setIsUpdate={setIsUpdate}
                _data_list={data_list}
                data_country_list={data_country_list}
                getDemande={getDemande}
              />
            </>
          ),
        },
        {
          id: 2,
          name: "Réponse",
          content: (
            <>
              <RepondreDemande
                _setIsOnResponsDemand={setIsOnResponsDemand}
                _demande={_demande}
                _idUser={idUser}
                handleCloseModal={handleCloseModal}
                _sous_traitans={_sous_traitans}
                answer={answer}
                getData={getData}
                isShowUpdate={false}
                _data_list={data_list}
              />
            </>
          ),
        },
        {
          id: 3,
          name: "Relance",
          content: (
            <>
              <Relance
                handleCloseModal={handleCloseModal}
                idUser={idUser}
                _demande={_demande}
                relance={relance}
                getDemande={getDemande}
                all_canal={all_canal}
                justShow={true}
              />
            </>
          ),
        },
        {
          id: 4,
          name: "Mails",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "eml", icone: "fa fa-envelope" },
                    { fileType: "email", icone: "fa fa-file" },
                    { fileType: "oft", icone: "fa fa-envelope" },
                    { fileType: "msg", icone: "fa fa-envelope" },
                  ]}
                  name_input="file"
                  size_file={100}
                  set_document={handleChangeEmail}
                  data_file={req_file_email}
                  title="Mails ajoutés"
                  deleteFile={onDeleteEmail}
                  isUpdate={_demande && !isUpdate}
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                  justeShow={false}
                />
              </Form>
            </>
          ),
        },
        {
          id: 5,
          name: "Documents",
          content: (
            <>
              <Form
                formData={form_data}
                submitFunction={submitAddDevis}
                formRef={formRef}
              >
                <InputFile
                  files={[
                    { fileType: "pdf", icone: "fa fa-envelope" },
                    { fileType: "docx", icone: "fa fa-file" },
                    { fileType: "jpeg", icone: "fa fa-thin fa-image" },
                    { fileType: "jpg", icone: "fa fa-thin fa-image" },
                    { fileType: "png", icone: "fa fa-thin fa-image" },
                  ]}
                  name_input="file"
                  size_file={1}
                  set_document={handleChangeDocument}
                  title="Documents ajoutés"
                  data_file={req_file_image}
                  deleteFile={onDeleteDoc}
                  isUpdate={_demande && !isUpdate}
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                  justeShow={false}
                />
              </Form>
            </>
          ),
        },
        {
          id: 6,
          name: "Refus",
          content: (
            <>
              <Invalide _demande={_demande} />
            </>
          ),
        },
        ...show_log(7),
      ];
      break;
    case 6:
      break;
    default:
      data_tab = [];
      break;
  }

  return (
    <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
      <div
        className="modal-header container-fluid d-flex flex-column"
        style={{ minHeight: "5%" }}
      >
        <div
          className="d-flex flex-row w-100 align-items-center"
          style={{ height: "100%" }}
        >
          <HeaderModal
            _isOnResponsDemand={isOnResponsDemand}
            btnClick={setIsOnResponsDemand}
            demande={_demande}
            modalData={modalData}
            handleCloseModal={() => {
              setActiveTab(1);
              handleCloseModal();
              setIsUpdate(false);
              setIsOnResponsDemand(false);
            }}
            set_update_action={set_update_action}
            show_bouton_valide={!show_notif && can_validate_answered_request}
            can_abort_request={can_abort_answered_request}
            can_refuse_request={can_refuse_answered_request}
            idUser={idUser}
            getData={getData}
          />
        </div>
      </div>
      {/* afficher form repondre au commande  */}
      {!isOnResponsDemand ? (
        <>
          <nav className="d-flex position-relative nav-gray">
            <div
              className="nav nav-tabs w-100"
              id="nav-tab"
              role="tablist"
              style={{ border: "none" }}
            >
              {data_tab?.map((tab) => (
                <button
                  key={tab.id}
                  className={`nav-link text-center flex-grow-1 ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                  id={`nav-${tab.name.toLowerCase()}-tab`}
                  onClick={() => handleTabClick(tab.id)}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${tab.name.toLowerCase()}`}
                  aria-selected={activeTab === tab.id ? "true" : "false"}
                >
                  <span className="text-muted" style={{ fontSize: "15px" }}>
                    {tab.name}
                    {tab?.show_notif && (
                      <i className="fa-solid fa-circle-exclamation text-danger ms-2"></i>
                    )}
                  </span>
                </button>
              ))}
            </div>
          </nav>
          <div className="modal-body p-0" style={{ minHeight: "80%" }}>
            {" "}
            <div className="tab-content" id="nav-tabContent">
              {data_tab.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab-pane fade ${
                    activeTab === tab.id ? "show active" : ""
                  }`}
                  id={`nav-${tab.name.toLowerCase()}`}
                  role="tabpanel"
                  aria-labelledby={`nav-${tab.name.toLowerCase()}-tab`}
                >
                  {tab.content}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-body p-0">
            <RepondreDemande
              _setIsOnResponsDemand={setIsOnResponsDemand}
              _demande={_demande}
              _idUser={idUser}
              handleCloseModal={handleCloseModal}
              _sous_traitans={_sous_traitans}
              answer={answer}
              getData={getData}
            />
          </div>
        </>
      )}
    </ModalForAddNew>
  );
};

export default UpdateDevis;
